/**
 * @prettier
 * @flow
 */

import classNames from 'classnames';
import { Responsive, Popup } from 'liana-ui/components/';
import { Table } from 'semantic-ui-react';
import type { IntlComponent } from 'react-intl';
import type { Props as PopupProps } from 'liana-ui/components/popup/Popup';

// prettier-ignore
type Props = {
	/** Key */
	key: string,
	/** Cell content. */
	content?: string | React.Node,
	/** Represents colspan of cell. */
	colSpan?: number,
	/** Represents rowspan of cell. */
	rowSpan?: number,
	/** A cell can be collapsing so that it only uses as much space as required. */
	collapsing: boolean,
	/** A cell can be selectable. */
	selectable?: boolean,
	/** A cell can appear sorted. */
	sorted?: boolean,
	/** A cell may call attention to a success or a positive value. */
	success?: boolean,
	/** A cell may call attention to a warning. */
	warning?: boolean,
	/** A cell may call attention to an error or a negative value. */
	error?: boolean,
	/** Keep cell opacity even if row is off . */
	ignoreOff?: boolean,
	/** Represents width of cell. Possible values: 1 - 12 */
	width?: string | number,
	/** A table cell can adjust its text alignment. */
	textAlign?: 'left' | 'center' | 'right',
	/** A table cell can adjust its text alignment. */
	verticalAlign?: 'bottom' | 'middle' | 'top',
	/** A table cell can specify that its contents should remain on a single line, and not wrap. */
	singleLine?: boolean,
	/** A table cell can break very long strings in tight spaces into multiple lines. */
	breakWord?: boolean,
	/** A table cell can keep content on a single line without breaking it to separate lines. */
	noWrap?: boolean,
	/** Smallest device that cell will be displayed with. */
	minDevice?: 'mobile' | 'tablet' | 'computer' | 'largescreen' | 'widescreen',
	/** Largest device that cell will be displayed with. */
	maxDevice?: 'mobile' | 'tablet' | 'computer' | 'largescreen' | 'widescreen',
	/** Popup text or object of properties for Popup */
	popup?: IntlComponent | string | PopupProps
};

const DEFAULTS = {
	collapsing: false,
	unsortable: false
};

/** COMPONENT BASED ON: https://react.semantic-ui.com/collections/table/ */
const Component: React.AbstractComponent<Props, mixed> = React.memo<Props>((props: Props) => {
	const getContent = () => {
		let content = props.content;

		if (props.breakWord) {
			content = <div className='break-word-wrap break-word'>{content}</div>;
		} else if (props.singleLine) {
			content = (
				<div className='break-word-wrap'>
					<div className='overflow-ellipsis'>{content}</div>
				</div>
			);
		}

		return content;
	};

	// Function to generate LianaUI Button
	const createCell = (props: Props) => {
		// Assign classes
		const classes = classNames({
			sorted: props.sorted && !props.colSpan,
			nowrap: props.noWrap,
			'ignore-off': props.ignoreOff
		});

		// Define cell
		let cell = (
			<Table.Cell
				positive={props.success}
				warning={props.warning}
				error={props.error}
				key={props.key}
				className={classes}
				colSpan={props.colSpan}
				rowSpan={props.rowSpan}
				collapsing={props.collapsing}
				selectable={props.selectable}
				width={props.width}
				textAlign={props.textAlign}
				verticalAlign={props.verticalAlign}
			>
				{getContent()}
			</Table.Cell>
		);

		// Display popup
		if (props.popup) {
			cell =
				typeof props.popup === 'string' || React.isValidElement(props.popup) ? (
					<Popup trigger={cell} text={props.popup} />
				) : (
					<Popup trigger={cell} {...props.popup} />
				);
		}

		return cell;
	};

	// Display reponsively
	let component =
		props.minDevice || props.maxDevice || props.hideTouch ? (
			<Responsive {...props}>{createCell(props)}</Responsive>
		) : (
			createCell(props)
		);

	return component;
});

// Documentation generation support
Component.displayName = 'TableHeaderCell';
Component.defaultProps = DEFAULTS;

export type { Props };
export default Component;
