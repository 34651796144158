/**
 * @prettier
 * @flow
 */

import classNames from 'classnames';
import { Table } from 'semantic-ui-react';
import { Transition } from 'liana-ui/components/';

type Props = {
	/** Key */
	reactKey: string,
	/** Primary content. */
	children?: React.Node,
	/** A row can be active or selected by a user. */
	active: boolean,
	/** A row can glow when added. */
	added: boolean,
	/** A row can animate (fly away) when deleted. */
	deleted: boolean,
	/** A row can appear to be off. */
	off: boolean,
	/** A row can be disabled. */
	disabled: boolean,
	/** Callback on when delete animation ends. */
	onAfterDeleteRows: () => void
};

const DEFAULTS = {
	active: false,
	added: false,
	deleted: false,
	off: false,
	disabled: false
};

/** COMPONENT BASED ON: https://react.semantic-ui.com/collections/table/ */
const Component: React.AbstractComponent<Props, mixed> = React.memo<Props>((props: Props) => {
	// Assign classes
	const classes = classNames({
		off: props.off
	});

	// Delete transition
	let visible = props.deleted ? !props.deleted : true,
		transitionOnMount = props.deleted ? false : props.added ? true : null,
		animation = props.deleted ? 'fly left' : props.added ? 'glow' : null,
		duration = props.deleted ? 500 : props.added ? 1000 : 0,
		onHide = props.deleted ? props.onAfterDeleteRows : null,
		style = props.deleted ? { background: 'yellow' } : undefined;

	// Define row
	let row = (
		<Transition
			visible={visible}
			transitionOnMount={transitionOnMount}
			unmountOnHide={true}
			animation={animation}
			duration={duration}
			onHide={onHide}
			reactKey={`animation-${props.reactKey}`}
		>
			<Table.Row
				style={style}
				active={props.active}
				disabled={props.disabled}
				className={classes}
				reactKey={props.reactKey}
			>
				{props.children}
			</Table.Row>
		</Transition>
	);

	return row;
});

// Documentation generation support
Component.displayName = 'TableRow';
Component.defaultProps = DEFAULTS;

export type { Props };
export default Component;
