/**
 * @prettier
 * @flow
 */

import { useState } from 'react';
import classNames from 'classnames';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import { Label, LimitModal } from 'liana-ui/components/';
import type { IntlComponent } from 'react-intl';

type LimitLabelTranslations = {
	amount: string,
	upgrade: string,
	limit: string,
	limitReached: string,
	modalHeader: string,
	messageHeader: string,
	close: string
};

// prettier-ignore
type Props = {
	/** A default limit label/modal must have upgrade page url */
	upgradeLink: string,
	/** A limit label must have current amount of items. */
	amount?: number,
	/** A limit label must have limit amount of items. */
	limit?: number,
	/** A limit label can hide the current items amount. */
	hideAmount?: boolean,
	/**
		A limit label modal image can be changed
	*/
	image?: string,
	/**
		A limit label modal description can be changed.
		PROPS[IntlComponent=/localization/]
	*/
	description?: string | IntlComponent,
	/** A limit label can have different sizes. */
	size?: 'tiny' | 'large',
	/** Smallest device that component will be displayed with. */
	minDevice?: 'mobile' | 'tablet' | 'computer' | 'largescreen' | 'widescreen',
	/** Largest device that component will be displayed with. */
	maxDevice?: 'mobile' | 'tablet' | 'computer' | 'largescreen' | 'widescreen',
	/** Hide content on touch devices */
	hideTouch?: boolean,
	/** Custom translations. */
	translations?: LimitLabelTranslations,
	/** Function called on link click. Will override the opening of the default LimitModal. */
	onClick?: (
		event: SyntheticEvent<>,
		data: {
			name: string,
			off: boolean
		}
	) => void
};

const DEFAULTS = {
	size: 'small',
	amount: 0,
	hideAmount: false,
	translations: {
		amount: 'component.limit-label.amount',
		upgrade: 'component.limit-label.upgrade',
		limit: 'component.limit-label.limit',
		limitedFeature: 'component.limit-label.limitedFeature',
		limitReached: 'component.limit-label.limitReached',
		modalHeader: 'component.limit-label.modalHeader',
		messageHeader: 'component.limit-label.messageHeader',
		close: 'component.modal.close'
	}
};

const WARNING_LIMIT = 80;

/** COMPONENT BASED ON: https://react.semantic-ui.com/elements/label */
const Component: React.AbstractComponent = React.memo((props: Props) => {
	// Merge translations
	let translations = Object.assign({}, DEFAULTS.translations, props.translations);

	// Modal state
	let [modalOpen, setModalOpen] = useState(false);

	const handleClick = (event: ?SyntheticEvent<>, data: any) => {
		if (props.upgradeLink) {
			setModalOpen(true);
		}
		props.onClick(event, data);
	};

	// Init
	const percentage = (props.amount / props.limit) * 100,
		icon = {
			name: 'fa-gem',
			color: 'purple'
		},
		limitText =
			percentage >= 100 ? (
				<FormattedMessage id={translations.limitReached} />
			) : (
				<>
					<FormattedMessage id={translations.limit} />:{' '}
					<FormattedNumber value={props.limit} notation='compact' compactDisplay='short' />
				</>
			),
		detailClasses = classNames('text-bolder', {
			'remove-left-margin': props.hideAmount
		}),
		limit =
			props.amount && props.limit
				? {
						content: limitText,
						className: detailClasses,
						icon: icon.color ? icon : undefined,
						onClick: handleClick,
						popup: {
							text: <FormattedMessage id={translations.upgrade} />,
							offset: [0, 5],
							size: 'mini'
						}
				  }
				: {
						content: <FormattedMessage id={translations.limitedFeature} />,
						className: 'remove-left-margin',
						icon: icon.color ? icon : undefined,
						onClick: handleClick,
						popup: {
							text: <FormattedMessage id={translations.upgrade} />,
							offset: [0, 5],
							size: 'mini'
						}
				  };
	return (
		<>
			<Label
				text={
					!props.hideAmount && props.amount ? (
						<FormattedMessage id={translations.amount} values={{ amount: props.amount }} />
					) : null
				}
				detail={limit}
				size={props.size}
				maxDevice={props.maxDevice}
				minDevice={props.minDevice}
				hideTouch={props.hideTouch}
			/>
			{props.upgradeLink ? (
				<LimitModal
					open={modalOpen}
					image={props.image}
					description={props.description}
					translations={translations}
					upgradeLink={props.upgradeLink}
					onClose={() => setModalOpen(false)}
				/>
			) : null}
		</>
	);
});

// Documentation generation support
Component.displayName = 'LimitLabel';
Component.defaultProps = DEFAULTS;

export type { Props, LimitLabelTranslations };
export default Component;
