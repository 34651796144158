// Centralized place to handle common app initialization routines
import Popup from './Popup';
import Browser from '../browser/Browser';
import { useLocation } from 'react-router-dom';

export default {
	init: () => {
		Browser.detectPointer(); // Add `pointer-device` class to `<html>` tag if pointer only device is detected.
		Browser.detectBreakpoints();   // Add breakpoint class (`mobile`, `tablet`, `computer` etc.) to `<html>` tag and update it dynamically when screen size changes.
		Popup.init(); // Initialize legacy jQuery legacy popups.
	}
};
