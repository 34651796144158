/**
 * @prettier
 * @flow
 */

import { IconGroup } from 'semantic-ui-react';
import { Icon, Popup } from 'liana-ui/components/';
import type { Props as IconProps } from 'liana-ui/components/icon/Icon';
import type { Props as PopupProps } from 'liana-ui/components/popup/Popup';

// prettier-ignore
type Props = {
	/**
		Array of props for Icon.
		PROPS[IconProps=/components/labels/icons/icon/]
	*/
	icons: Array<IconProps>,
	/** Size of the icon group. */
	size?: 'small' | 'large' | 'huge',
	/**
		Popup text or, react-intl coomponent or object of properties for Popup component.
		PROPS[IntlComponent=/language/localisation/, PopupProps=/components/modals/popup/]
	*/
	popup?: string | IntlComponent | PopupProps,
};

const DEFAULTS = {
	inverted: false
};

/** COMPONENT BASED ON: https://react.semantic-ui.com/elements/icon/#groups-icon-group */
let Component: React.AbstractComponent = React.memo<Props>((props: Props) => {
	let icons = props.icons ? (
		<IconGroup size={props.size}>
			{props.icons ? props.icons.map((icon, index) => <Icon key={index} {...icon} />) : null}
		</IconGroup>
	) : null;

	// Display popup
	if (props.popup) {
		icons =
			typeof props.popup === 'string' || React.isValidElement(props.popup) ? (
				<Popup trigger={icons} text={props.popup} />
			) : (
				<Popup trigger={icons} {...props.popup} />
			);
	}

	return icons;
});

// Documentation generation support
Component.displayName = 'Icon.Group';
Component.defaultProps = DEFAULTS;

export type { Props };
export default Component;
