/**
 * @prettier
 * @flow
 */

import { debounce } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Loader } from 'semantic-ui-react';
import { Dimmer } from 'liana-ui/components/';
import Utils from './LoaderUtils';

// prettier-ignore
type Props = {
	/** A loader can have text. */
	text?: string | FormattedMessage,
	/** A loader can be active. */
	active?: boolean,
	/** A loader can be formatted to appear on dark backgrounds. */
	inverted?: boolean,
	/** A loader can appear inline with content. */
	inline?: boolean | 'centered',
	/** A loader can overlay containers or full screen page. */
	dimmer?: boolean | 'page',
	/** A loader can have a differnt color based on state. */
	color?: 'purle' | 'green' | 'yellow' | 'red' ,
	/** A loader can be different speeds. */
	speed?: 'slow' | 'fast',
	/** A loader can be placed in the middle of ampty space. */
	centerInEmptySpace?: boolean
};

const DEFAULTS = {
	active: false,
	inverted: false,
	dimmer: false,
	centerInEmptySpace: false
};

/** COMPONENT BASED ON: https://react.semantic-ui.com/elements/loader/ */
const Component: React.AbstractComponent<Props, mixed> = React.memo<Props>((props: Props) => {
	const WRAPPER_OFFSET = 400;

	let [hasListeners, setHasListeners] = useState(false);
	let loaderWrapperRef = useRef();
	let icon: HTMLElement = null;
	let container: HTMLElement = null;

	// Assign defaults
	props = Object.assign(
		{
			dimmer: props.dimmer === true || props.dimmer === 'page' ? props.dimmer : false,
			inline:
				props.inline === false || props.inline === 'centered'
					? props.inline
					: props.centerInEmptySpace || props.dimmer
					? false
					: true
		},
		DEFAULTS,
		props
	);

	// Case centerInEmptySpace is set inline needs to be false
	if (props.centerInEmptySpace || props.dimmer) {
		props.inline = false;
	}

	// componentDidMount
	useEffect(() => {
		init();
	}, []);

	// componentDidUpdate
	useEffect(() => {
		init();
	});

	// componentWillUnmount
	useEffect(() => {
		return () => {
			window.removeEventListener('scroll', callback);
			window.removeEventListener('resize', callback);
		};
	}, []);

	const init = () => {
		icon = loaderWrapperRef.current.querySelector('.loader');
		container = loaderWrapperRef.current.parentNode;

		if (props.dimmer !== 'page' && !props.inline) {
			if (props.centerInEmptySpace) {
				setWrapperHeight();
			}
			Utils.center(container, icon, props.dimmer);
			if (!hasListeners) {
				window.addEventListener('scroll', callback);
				window.addEventListener('resize', callback);
				setHasListeners(true);
			}
		}
	};

	const setWrapperHeight = () => {
		let loaderWrapperHeight =
			window.document.body.scrollHeight -
			container.getBoundingClientRect().top -
			Utils.getPadding(container, props.dimmer);

		loaderWrapperRef.current.style.height =
			loaderWrapperHeight < WRAPPER_OFFSET ? `${WRAPPER_OFFSET}px` : `${loaderWrapperHeight}px`;
	};

	let callback = debounce(() => Utils.center(container, icon, props.dimmer), 5); // Throttle

	let loader = (
		<>
			<Loader
				className={props.speed}
				content={!props.inline && props.text ? props.text : undefined}
				active={props.active}
				inverted={props.inverted}
				inline={props.inline}
				size={props.size}
			/>
			{props.inline && props.text ? props.text : null}
		</>
	);

	if (props.dimmer) {
		const isFullScreen = props.dimmer === 'page';
		loader = (
			<Dimmer active={props.active} inverted={isFullScreen ? false : true} page={isFullScreen}>
				{loader}
			</Dimmer>
		);
	}

	return (
		<div
			ref={loaderWrapperRef}
			className={props.centerInEmptySpace ? 'loader-wrapper relative' : 'loader-wrapper'}
			style={{
				display: props.active ? (props.inline ? 'inline-block' : 'block') : 'none',
				opacity: (props.centerInEmptySpace && hasListeners) || !props.centerInEmptySpace ? 1 : 0
			}}
		>
			{loader}
		</div>
	);
});

Component.displayName = 'Loader';
Component.defaultProps = DEFAULTS;

export type { Props };
export default Component;
