/**
 * @prettier
 * @flow
 */

import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { Text, Icon, Segment, Divider, ActionHeader } from 'liana-ui/components';
import ScrollContainer from 'liana-ui/legacy/components/scroll-container/ScrollContainer';

// prettier-ignore
type Props = {
	/** Widget ID */
	id: number | string,
	/** Content to place inside .grid-stack element */
	children: React.Node,
	/** Title for widget. Can contain link ans labels */
	title: React.Node,
	/** Sub title for widget. Can contain link ans labels */
	subTitle?: React.Node,
	/** Type of widget (Possible types atm: add) */
	type?: string,
	/** Display widget in public mode. Disables for example all editing. */
	publicMode?: boolean,
	/** Callback when widget type 'add' is clicked */
	onAdd?: () => mixed,
	/** Callback when widget delete action is clicked */
	onDelete?: () => mixed,
	/** Callback when widget settings action is clicked */
	onSettings?: () => mixed,
	/** Callback to trigger when component has mounted */
	onMount?: () => mixed
};

const Component: React.AbstractComponent<Props, mixed> = React.memo<Props>((props: Props) => {
	const pointerOnly = document.querySelector('html').classList.contains('pointer-device');
	const grid = document.querySelector('.grid-stack') && document.querySelector('.grid-stack').gridstack;

	const onMount = () => {
		if (typeof props.onMount === 'function') {
			props.onMount();
		}
	};

	const handleDelete = () => {
		if (typeof props.onDelete === 'function') {
			props.onDelete();
		} else {
			if (grid) {
				const self = document.querySelector(`.grid-stack-item[data-gs-id="${props.id}"]`);
				// False to not remove node. Let React handle in onDelete
				grid.removeWidget(self, false);
			}
		}
	};

	const handleMenuOpen = () => {
		if (grid && pointerOnly) {
			grid.enableMove(false);
		}
	};

	const handleMenuClose = () => {
		if (grid && pointerOnly) {
			grid.enableMove(true);
		}
	};

	useEffect(onMount, []);

	return (
		<div className={`grid-stack-item-content ${props.type === 'add' ? 'add-widget' : ''}`.trim()}>
			{props.type === 'add' ? (
				<Segment as='a' raised hoverable textAlign='center' onClick={props.onAdd}>
					<Icon name='fa-plus' size='massive' />
					<Text as='div' color='black'>
						Add new chart
					</Text>
				</Segment>
			) : (
				<Segment raised removePaddings='all' removeMargins='all'>
					<ActionHeader
						stackable={false}
						sticky={false}
						header={{
							text: props.title,
							subheader: props.subTitle
						}}
						contextMenu={{
							size: 'small',
							options: [
								{ header: <FormattedMessage id='component.widget.actions' /> },
								{
									text: <FormattedMessage id='component.widget.settings' />,
									icon: 'settings',
									onClick: props.onSettings
								},
								{ divider: true },
								{
									text: <FormattedMessage id='component.widget.delete' />,
									icon: 'trash alternate',
									onClick: handleDelete
								}
							]
						}}
					/>
					<Segment basic compressed removePaddings='vertical' removeMargins='all'>
						<Divider removeMargins='all' />
					</Segment>
					<ScrollContainer classes='ui segment compressed basic remove-vertical-paddings remove-margins'>
						{props.children}
					</ScrollContainer>
				</Segment>
			)}
		</div>
	);
});

Component.displayName = 'Widget';
Component.defaultProps = {
	onMount: null
};

export default Component;
