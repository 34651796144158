
// Minimum JSON fetch API
// https://developers.google.com/web/updates/2015/03/introduction-to-fetch
// TODO: Missing polyfill? - https://github.com/github/fetch
// Note: There is no request cancellation for fetch - https://github.com/whatwg/fetch/issues/20

let Fetch = {
	call: async function(url, opts = {}) {
		opts = Object.assign({ credentials: 'include' }, opts);
		return fetch(url, opts).then(async function(response) {
			if (response.status !== 200) {
				console.error(`Request to ${url} responded with status: ${response.status}`);
				return null;
			}
			return await response.json();
		}).catch(err => {
			console.error(`Request to ${url} responded with: ${err}`);
			return null;
		});
	},
	get: function(url) { return this.call(url, { method: 'GET' }) },
	post: function(url, data) {
		return this.call(url, {
			method: 'POST',
			body: JSON.stringify(data),
			headers: {"Content-type": "application/json; charset=UTF-8"}
		})
	}
};

const Get = Fetch.get;
const Post = Fetch.post;

export { Get, Post };

export default Fetch;
