/**
 * @prettier
 * @flow
 */

import { Message } from 'semantic-ui-react';
import classNames from 'classnames';
import { Icon, Loader } from 'liana-ui/components/';
import MessageHeader from './src/MessageHeader';
import MessageContent from './src/MessageContent';
import type { IntlComponent } from 'react-intl';

// prettier-ignore
type Props = {
	/**
		A message can have a header.
		PROPS[IntlComponent=/localization/]
	*/
	header?: string | IntlComponent,
	/** A message can have content. */
	content: React.Node,
	/**
		A message can have an icon.
		DATA[https://react.semantic-ui.com/elements/icon/]
	 */
	icon?: string,
	/** A message can have different layouts. */
	layout?: 'small' | 'big' | 'content',
	/** A message can be formatted to display information. */
	info?: boolean,
	/** A message can be formatted to display a positive message.  */
	success?: boolean,
	/** A message can be formatted to display warning messages.  */
	warning?: boolean,
	/** A message can be formatted to display a negative message.  */
	error?: boolean,
	/** A message can be formatted to display loading state.  */
	loading?: boolean,
	/** A message can be formatted to appear to be off.  */
	off?: boolean,
	/** A message can only take up the width of its content. */
	compact?: boolean,
	/** A message can be compressed to have less padding. */
	compressed?: boolean,
	/** A big layout message can be formatted to be vertical. */
	vertical?: boolean,
	/** A message can align content text. */
	textAlign?: 'left' | 'center',
	/**
		A message margins can be removd around the message.
		VALUES['all' | 'top' | 'bottom']
	*/
	removeMargins?: string,
	/** A message can be diffreent size. */
	size?: 'mini' | 'tiny' | 'small'
};

const DEFAULTS = {
	info: false,
	success: false,
	warning: false,
	error: false,
	loading: false,
	off: false,
	layout: 'small',
	vertical: false,
	size: 'small'
};

/** COMPONENT BASED ON: https://react.semantic-ui.com/collections/message/ */
const Component: React.AbstractComponent<Props, mixed> = React.memo<Props>((props: Props) => {
	// Assign classes
	let classes = classNames({
		vertical: props.vertical,
		icon: props.layout === 'big',
		off: props.off,
		compressed: props.compressed,
		'remove-top-margin': props.removeMargins === 'top' || props.removeMargins === 'all',
		'remove-bottom-margin': props.removeMargins === 'bottom' || props.removeMargins === 'all',
		'remove-margins': props.removePaddings === 'all'
	});

	// Get message content based on layout
	function getLayout() {
		let icon = getIcon();
		return props.layout === 'big' ? (
			<>
				{props.loading && !props.icon ? (
					<span className='icon'>
						<Loader active inline />
					</span>
				) : icon && props.layout !== 'content' ? (
					<Icon name={icon.big} color={icon.color} spinning={props.loading} />
				) : null}
				<MessageContent>
					{props.header ? <MessageHeader size='large'>{props.header}</MessageHeader> : null}
					{props.content}
				</MessageContent>
			</>
		) : (
			<>
				{props.header ? <MessageHeader>{props.header}</MessageHeader> : null}
				{props.loading && !props.icon ? (
					<span className='icon'>
						<Loader active inline size='tiny' />
					</span>
				) : icon?.small && props.layout !== 'content' ? (
					<Icon name={icon.small} color={icon.color} spinning={props.loading} />
				) : null}
				{props.content}
			</>
		);
	}

	// Get icon based on message type
	function getIcon() {
		if (props.layout !== 'content') {
			switch (true) {
				case props.info:
					return {
						small: props.icon || 'fa-info-circle',
						big: props.icon || 'fa-info-circle',
						color: 'purple'
					};
				case props.success:
					return {
						small: props.icon || 'fa-check',
						big: props.icon || 'fa-check',
						color: 'green'
					};
				case props.warning:
					return {
						small: props.icon || 'fa-circle-exclamation',
						big: props.icon || 'fa-circle-exclamation',
						color: 'yellow'
					};
				case props.error:
					return {
						small: props.icon || 'fa-triangle-exclamation',
						big: props.icon || 'fa-triangle-exclamation',
						color: 'red'
					};
				default:
					return {
						small: props.icon,
						big: props.icon,
						color: 'grey'
					};
			}
		}
		return undefined;
	}

	// Get color on message type
	function getColor() {
		switch (true) {
			case props.info:
				return 'purple';
			case props.success:
				return 'green';
			case props.warning:
				return 'yellow';
			case props.error:
				return 'red';
			default:
				return null;
		}
	}

	let message = (
		<Message color={getColor()} size={props.size} compact={props.compact} className={classes}>
			{getLayout()}
		</Message>
	);

	return message;
});

// Documentation generation support
Component.displayName = 'Message';
Component.defaultProps = DEFAULTS;

export type { Props };
export default Component;
