/**
 * @prettier
 * @flow
 */

import { useEffect } from 'react';
import classNames from 'classnames';
import { Sticky } from 'semantic-ui-react';
import DateRange from 'liana-ui/legacy/components/date-range/DateRange';
import { Segment, Header, Button, Dropdown, Search, Checkbox, Filter, ContextMenu, Grid } from 'liana-ui/components/';
import type { Props as HeaderProps } from 'liana-ui/components/header/Header';
import type { Props as ButtonProps } from 'liana-ui/components/button/Button';
import type { Props as ButtonGroupProps } from 'liana-ui/components/button/ButtonGroup';
import type { Props as ContextMenuProps } from 'liana-ui/components/menu/ContextMenu';
import type { Props as SearchProps } from 'liana-ui/components/search/Search';
import type { Props as DropdownProps } from 'liana-ui/components/dropdown/Dropdown';
import type { Props as FilterProps } from 'liana-ui/components/filter/Filter';
import type { Props as DateRangeProps } from 'liana-ui/legacy/components/date-range/DateRange';

// prettier-ignore
type Props = {
	/**
		An action header can have a header.
		PROPS[HeaderProps=/components/texts/header/]
	*/
	header?: HeaderProps,
	/**
		An action header can have a single or multiple action buttons.
		PROPS[ButtonProps=/components/buttons/button/]
	*/
	buttons?: ButtonProps | Array<ButtonProps>,
	/**
		An action header can have a single or multiple button groups.
		PROPS[ButtonGroupProps=/components/buttons/button-group/]
	*/
	buttonGroup?: ButtonGroupProps | Array<ButtonGroupProps>,
	/**
		An action header can have a single or multiple context menus.
		PROPS[ContextMenuProps=/components/menus/context-menu/]
	*/
	contextMenu?: ContextMenuProps | Array<ContextMenuProps>,
	/**
		An action header can have a dropdown for a single filter. Multiple filters (filter property) should be added to filter sdebar using filter property.
		PROPS[DropdownProps=/components/forms/dropdowns/dropdown/]
	*/
	dropdown?: DropdownProps,
	/**
		An action header can have a date filter.
		PROPS[ButtonProps=/components/buttons/button, DateRangeProps=/components/forms/pickers/date-range/]
	*/
	dateFilter?: {
		color: 'primary',
		buttons: Array<ButtonProps>,
		dateRange: DateRangeProps
	},
	/**
		An action header can have a search field.
		PROPS[SearchProps=/components/forms/search/]
	*/
	search?: SearchProps,
	/**
		An action header can have a checkbox or toggle.
		PROPS[CheckboxProps=/components/forms/checkbox/]
	*/
	checkbox?: CheckboxProps,
	/**
		An action header can have multiple filters in a side panel. An action header can not have a single filter dropdown (dropdown property) at the same time.
		PROPS[FilterProps=/components/forms/filter/]
	*/
	filter?: FilterProps,
	/** An action header can be a page level header */
	page?: boolean,
	/** An action header can have a divider border at the bottom */
	dividing?: boolean,
	/** An action header can be compressed */
	compressed?: boolean,
	/** An action header can be smaller in size. */
	size?: 'small',
	/** An action header can be stackable. */
	stackable?: boolean,
	/** An action header can be sticky. */
	sticky?: boolean,
	/** Context which sticky header should stick to. */
	stickyContext?: React.ElementRef,
	/** An action header can scroll to an element (CSS selector) on search and filter. */
	scrollTo?: string
};

const DEFAULTS = {
	sticky: true,
	stickyContent: undefined,
	stackable: true,
	page: false,
	dividing: false,
	compressed: true
};

// If header is currently sticky
let stickyActive = false;

/** COMPONENT BASED ON: https://react.semantic-ui.com/elements/icon */
const Component: React.AbstractComponent<Props, mixed> = React.memo<Props>((props: Props) => {
	// Triggered on filter panel filters change
	let activeFilters = props.filter ? props.filter.activeFilters : 0;
	useEffect(() => {
		scrollUp();
	}, [activeFilters, props.search?.value]);

	// Component unmount
	useEffect(() => {
		return () => {
			stickyActive = false;
		};
	}, []);

	const getButtons = () => {
		// Check if exists
		if (!props.buttons && !props.contextMenu) {
			return null;
		}

		let buttons = [];
		if (Array.isArray(props.buttons) && props.buttons.length > 0) {
			props.buttons.map((button, index) => {
				buttons.push(
					<Button
						circular={button.circular === false ? false : true}
						size={button.size ? button.size : props.size}
						fitted={!props.contextMenu && props.buttons.length === index + 1}
						{...button}
						key={`button-${index}`}
					/>
				);
			});
		} else if (props.buttons) {
			buttons.push(
				<Button
					circular={props.buttons.circular === false ? false : true}
					size={props.buttons.size ? props.buttons.size : props.size}
					fitted={!props.contextMenu}
					{...props.buttons}
					key='button'
				/>
			);
		}

		if (Array.isArray(props.contextMenu) && props.contextMenu.length > 0) {
			props.contextMenu.map((contextMenu, index) => {
				buttons.push(
					<ContextMenu
						size={props.size}
						fitted={props.contextMenu.length === index + 1}
						{...contextMenu}
						key={`contextmenu-${index}`}
					/>
				);
			});
		} else if (props.contextMenu) {
			buttons.push(<ContextMenu size={props.size} fitted {...props.contextMenu} key='context-menu' />);
		}

		return <Grid.Column collapsing>{buttons}</Grid.Column>;
	};

	const getDropdown = () => {
		// Check if exists and no other filters
		if (!props.dropdown || props.filter) {
			return null;
		}

		return (
			<Grid.Column collapsing>
				<Dropdown
					size={props.size}
					icon='options'
					onChange={(event, data) => handleDropdownChange(event, data)}
					{...props.dropdown}
				/>
			</Grid.Column>
		);
	};

	const getDateFilter = () => {
		// Check if exists
		if (!props.dateFilter) {
			return null;
		}

		let content = null,
			buttons = [],
			dateRange = null;

		if (props.dateFilter.buttons && props.dateFilter.buttons.length > 0) {
			props.dateFilter.buttons.map((button, index) => {
				buttons.push(
					<Button
						{...button}
						onClick={(event, data) => {
							scrollUp();
							if (typeof button.onClick === 'function') {
								button.onClick(event, data);
							}
						}}
						key={`date-filter-button-${index}`}
					/>
				);
			});
		}

		if (props.dateFilter.dateRange) {
			dateRange = (
				<DateRange
					{...props.dateFilter.dateRange}
					hideInput={props.dateFilter.buttons && props.dateFilter.buttons.length > 0}
					buttonClasses={props.dateFilter.dateRange.buttonClasses}
					tooltip={
						props.dateFilter.buttons && props.dateFilter.buttons.length > 0
							? { 'data-content': props.dateFilter.dateRange.popup, 'data-variation': 'tiny' }
							: undefined
					}
					onChange={(start, end, name, id) => {
						scrollUp();
						if (typeof props.dateFilter.dateRange.onChange === 'function') {
							props.dateFilter.dateRange.onChange(start, end, name, id);
						}
					}}
				/>
			);
		}

		if (buttons.length) {
			content = (
				<Button.Group fitted fluid size={props.size}>
					{buttons}
					{dateRange}
				</Button.Group>
			);
		} else {
			content = dateRange;
		}

		return (
			<Grid.Column collapsing fluid>
				{content}
			</Grid.Column>
		);
	};

	const getSearch = () => {
		// Check if exists
		if (!props.search) {
			return null;
		}

		return (
			<Grid.Column collapsing fluid>
				<Search
					size={props.size}
					fluid
					onSearchChange={(event, data) => handleSearchChange(event, data)}
					{...props.search}
				/>
			</Grid.Column>
		);
	};

	const getCheckbox = () => {
		// Check if exists
		if (!props.checkbox) {
			return null;
		}

		return (
			<Grid.Column collapsing fluid>
				<Checkbox size={props.size} {...props.checkbox} />
			</Grid.Column>
		);
	};

	const getButtonGroup = () => {
		// Check if exists and no other filters
		if (!props.buttonGroup) {
			return null;
		}

		let buttonGroups = [];
		if (Array.isArray(props.buttonGroup) && props.buttonGroup.length > 0) {
			props.buttonGroup.map((group, index) => {
				buttonGroups.push(
					<Grid.Column collapsing key={`buttoncolumn-${index}`}>
						<Button.Group fitted size={props.size} {...group} key={index} />
					</Grid.Column>
				);
			});
		} else if (props.buttonGroup) {
			buttonGroups.push(
				<Grid.Column collapsing key='buttoncolumn'>
					<Button.Group fitted size={props.size} {...props.buttonGroup} key='button-group' />
				</Grid.Column>
			);
		}

		return buttonGroups;
	};

	const getFilter = () => {
		// Check if exists
		if (!props.filter) {
			return null;
		}

		return (
			<Grid.Column collapsing>
				<Filter fitted size={props.size} {...props.filter} />
			</Grid.Column>
		);
	};

	// Called on search change.
	const handleSearchChange = (event: ?SyntheticEvent<>, data: any) => {
		scrollUp();
		// Trigger onSearchChange callback
		if (typeof props.search.onSearchChange === 'function') {
			props.search.onSearchChange(event, data);
		}
	};

	// Called on dropdown change.
	const handleDropdownChange = (event: ?SyntheticEvent<>, data: any) => {
		scrollUp();
		// Trigger onChange callback
		if (typeof props.dropdown.onChange === 'function') {
			props.dropdown.onChange(event, data);
		}
	};

	// Called on dropdown change.
	const scrollUp = () => {
		if (props.scrollTo && stickyActive && props.sticky) {
			Safely.scroll(props.scrollTo);
		}
	};

	// Assign classes
	const classes = classNames('actionheader-wrapper', {
		dividing: props.dividing
	});

	let hasActions =
		props.buttons ||
		props.buttonGroup ||
		props.contextMenu ||
		props.search ||
		props.checkbox ||
		props.dateFilter ||
		props.dropdown ||
		props.filter;

	let header = (
		<Segment
			basic
			compressed={!props.page && props.compressed}
			removeMargins='all'
			removePaddings={props.page ? 'bottom' : undefined}
			className={classes}
		>
			<Grid columns={2} stackable={props.stackable}>
				<Grid.Column fluid verticalAlign='middle'>
					<Header
						as='h3'
						singleLine={!props.page}
						size={props.page ? 'massive' : props.size}
						{...props.header}
					/>
				</Grid.Column>
				{hasActions ? (
					<Grid.Column collapsing verticalAlign='top' floated='right'>
						<Grid stackabl={props.stackable} verticalAlign='middle' compact>
							{getCheckbox()}
							{getButtons()}
							{getButtonGroup()}
							{getDateFilter()}
							{getFilter()}
							{getDropdown()}
							{getSearch()}
						</Grid>
					</Grid.Column>
				) : null}
			</Grid>
		</Segment>
	);

	if (props.sticky && !props.page && !document.querySelector('html').classList.contains('mobile')) {
		header = (
			<Sticky
				context={props.stickyContext}
				onStick={() => (stickyActive = true)}
				onUnstick={() => (stickyActive = false)}
			>
				{header}
			</Sticky>
		);
	}

	return header;
});

// Documentation generation support
Component.displayName = 'ActionHeader';
Component.defaultProps = DEFAULTS;

export type { Props };
export default Component;
