/**
 * @prettier
 * @flow
 */

import classNames from 'classnames';
import { BrowserUtils } from 'liana-ui/definitions/';
import { Icon } from 'semantic-ui-react';
import IconGroup from './IconGroup';
import { Label, Popup, Responsive } from 'liana-ui/components/';
import type { IntlComponent } from 'react-intl';
import type { Props as PopupProps } from 'liana-ui/components/popup/Popup';

// prettier-ignore
type Props = {
	/**
		An icon must have a icon name as string.
		DATA[https://react.semantic-ui.com/elements/icon/]
	*/
	name: string,
	/** An icon can be solid instead outlined. */
	solid?: string,
	/** An icon can be a brand icon. */
	brand?: string,
	/** An icon can have a notification label with a number. */
	notification?: number,
	/** An icon can have an number on top of it */
	number?: number,
	/** An icon can appear on the left or right of the text. */
	iconPosition?: 'left' | 'right',
	/** An icon can formatted to appear circular. */
	circular?: boolean,
	/** An icon can formatted to appear squared. */
	squared?: boolean,
	/* An icon can be formatted to appear as an avatar. */
	avatar?: boolean,
	/** An icon can have its colors inverted for dark backgrounds. */
	inverted?: boolean,
	/** An icon can be fitted without space to left or right of icon. */
	fitted?: boolean,
	/**
		An icon can be different predefined color or hex color.
		VALUES['primary', 'red', 'orange', 'yellow', 'green', 'blue', 'grey', 'black', 'automation', 'mailer', 'press', 'cms', 'commerce', 'monitor', 'account', 'admin', '#FF0000', '#...']
	*/
	color?: string,
	/** An icon can be aligned to the left or right of its container. */
	floated?: 'left' | 'right',
	/** An icon can be different size. */
	size?: 'small' | 'large' | 'huge',
	/** An icon can have a spinning animation. */
	spinning?: boolean,
	/** An icon can be rotated by amount of degrees. */
	rotate?: number,
	/**
		An icon can be a smaller conrner icon.
		VALUES['top left', 'top right', 'bottom left', 'bottom right']
	*/
	corner?: boolean | string,
	/**
		Popup text or, react-intl coomponent or object of properties for Popup component.
		PROPS[IntlComponent=/language/localisation/, PopupProps=/components/modals/popup/]
	*/
	popup?: string | IntlComponent | PopupProps,
	/** Smallest device that component will be displayed with. */
	minDevice?: 'mobile' | 'tablet' | 'computer' | 'largescreen' | 'widescreen',
	/** Largest device that component will be displayed with. */
	maxDevice?: 'mobile' | 'tablet' | 'computer' | 'largescreen' | 'widescreen',
	/** Hide content on touch devices */
	hideTouch?: boolean,
	/** Function called on click. */
	onClick?: (
		event: SyntheticEvent<>,
		data: { name: string }
	) => void,
	/* DO NOT USE dataAttributes! Will be removed as soon as top panel is refactored. */
	dataAttributes?: {
		[string]:
		string
	}
};

const DEFAULTS = {
	solid: false,
	brand: false,
	notification: false,
	circular: false,
	square: false,
	inverted: false,
	fitted: false,
	spinning: false,
	iconPosition: 'left'
};

/** COMPONENT BASED ON: https://react.semantic-ui.com/elements/icon */
const Component: React.AbstractComponent<Props, mixed> = React.memo<Props>((props: Props) => {
	const handleClick = (event: ?SyntheticEvent<>, data: any) => {
		// Trigger onClick callback funtion
		if (typeof props.onClick === 'function') {
			props.onClick(event, handleCallbackData(data));
		}
	};

	// Handle data returned by callbacks.
	const handleCallbackData = (data: any) => {
		return {
			name: data.name
		};
	};

	// Function to generate LianaUI Button
	const createIcon = (props: Props) => {
		// Assign classes
		let classes = classNames({
			'fa-solid': props.solid,
			'fa-brands': props.brand,
			squared: props.squared,
			avatar: props.avatar,
			notification: props.notification,
			primary: props.color === 'primary',
			outlined: BrowserUtils.isLowContrast(props.color, 240) || props.color === 'white',
			loading: props.spinning,
			'float-right': props.floated === 'right',
			'float-left': props.floated === 'left',
			'icon-right': props.iconPosition === 'right',
			'number-icon': !isNaN(props.number),
			'cursor-pointer': props.onClick
		});

		// Assign color
		let color, style;
		if (typeof props.color === 'string') {
			if (props.color[0] === '#') {
				if (props.circular && props.inverted) {
					style = {
						background: props.color,
						color: '#FFF'
					};
				} else {
					style = {
						color: props.color
					};
				}

				delete props.color;
			}
		}

		// Assign rotate
		let rotate = props.rotate ? { transform: 'rotate(' + props.rotate + 'deg)' } : undefined;

		let icon = (
			<Icon
				className={classes}
				circular={props.circular || props.squared}
				inverted={props.inverted}
				fitted={props.fitted}
				color={props.color !== 'primary' ? props.color : undefined}
				corner={props.corner}
				name={props.name}
				size={props.size}
				onClick={handleClick}
				style={{ ...style, ...rotate }}
				{...props.dataAttributes} /** DO NOT USE dataAttributes! Will be removed as soon as top panel is refactored. */
			>
				{props.notification ? (
					<Label size='tiny' notification circular blinking text={String(props.notification)} />
				) : null}
				{!isNaN(props.number) ? <span className='number'>{props.number}</span> : null}
			</Icon>
		);

		// Display popup
		if (props.popup) {
			icon =
				typeof props.popup === 'string' || React.isValidElement(props.popup) ? (
					<Popup trigger={icon} text={props.popup} />
				) : (
					<Popup trigger={icon} {...props.popup} />
				);
		}

		return icon;
	};

	// Display reponsively
	let component =
		props.minDevice || props.maxDevice || props.hideTouch ? (
			<Responsive {...props}>{createIcon(props)}</Responsive>
		) : (
			createIcon(props)
		);

	return component;
});

// Documentation generation support
Component.displayName = 'Icon';
Component.defaultProps = DEFAULTS;

// Attach Subcomponents
Component.Group = IconGroup;

export type { Props };
export default Component;
