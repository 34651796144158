/**
 * @prettier
 * @flow
 */

import { LabelDetail } from 'semantic-ui-react';
import { Icon, Popup } from 'liana-ui/components/';
import type { Props as PopupProps } from 'liana-ui/components/popup/Popup';

// prettier-ignore
type Props = {
	/** Detail content. */
	content: FormattedMessage | string,
	/** Icon name or object of properties for Icon. */
	icon?: string | IconType,
	/* Additional classes. */
	className?: string,
	/** Popup text or object of properties for Popup */
	popup?: FormattedMessage | string | PopupProps,
	/** Called on click. */
	onClick?: (
		event: SyntheticEvent<>
	) => void
};

/** COMPONENT BASED ON: https://react.semantic-ui.com/elements/label */
const Component: React.AbstractComponent = React.memo((props: Props) => {
	// Called on checkbox click.
	const handleClick = (event: ?SyntheticEvent<>) => {
		if (typeof props.onClick === 'function') {
			props.onClick(event);
		}
	};

	let detail =
		props.icon || props.content ? (
			<LabelDetail as={props.onClick ? 'a' : undefined} className={props.className} onClick={handleClick}>
				{props.icon ? <Icon {...props.icon} /> : null}
				{props.content}
			</LabelDetail>
		) : null;

	// Display popup
	if (props.popup) {
		detail =
			typeof props.popup === 'string' || React.isValidElement(props.popup) ? (
				<Popup trigger={detail} text={props.popup} />
			) : (
				<Popup trigger={detail} {...props.popup} />
			);
	}

	return detail;
});

Component.displayName = 'LabelDetail';

export type { Props };
export default Component;
