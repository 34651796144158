/**
 * @prettier
 * @flow
 */

import classNames from 'classnames';
import { Checkbox } from 'semantic-ui-react';
import Popup from 'liana-ui/components/popup/Popup';
import CheckboxLabel from './src/CheckboxLabel';
import type { IntlComponent } from 'react-intl';
import type { Props as ImageProps } from 'liana-ui/components/image/Image';
import type { Props as IconProps } from 'liana-ui/components/icon/Icon';

// prettier-ignore
type Props = {
	/** An checkbox must have an input name */
	name: string,
	/** An checkbox can have diffrent HTML input type. */
	type: 'checkbox' | 'radio',
	/** A checkbox can have a value value. */
	value?: string | number,
	/** Initial state of checkbox. Use for uncontrolled components only. */
	defaultChecked?: string | number,
	/** Current state of checkbox. Use for controlled components only. */
	checked?: boolean,
	/** Initial state of indeterminate checkbox. Use for uncontrolled components only. */
	defaultIndeterminate?: boolean,
	/** Current state of indeterminate checkbox. Use for controlled components only. */
	indeterminate?: boolean,
	/**
		A checkbox can have a label.
		PROPS[IntlComponent=/localization/]
	*/
	label?: string | IntlComponent,
	/**
		A checkbox can have a description.
		PROPS[IntlComponent=/localization/]
	*/
	description?: string | IntlComponent,
	/** A checkbox can be a radio. This means it is an exclusive option. */
	radio?: boolean,
	/** A checkbox can be a toggle. This means it is an on/off option. */
	toggle?: boolean,
	/** A checkbox can have a flag. */
	flag?: string,
	/**
		A checkbox can have an image.
		PROPS[ImageProps=/components/texts/image/]
	*/
	image?: string | ImageProps,
	/**
		A checkbox can have an icon.
		PROPS[IconProps=/components/labels/icons/icon/]
	*/
	icon?: string | IconProps,
	/** Removes empty space around a checkbox that has no label. */
	fitted?: boolean,
	/** A checkbox label text can always keep all content on a single line. */
	singleLine?: boolean,
	/**
		A checkbox can be locked to indicate that the field is in use but can not be edited.
	*/
	locked?: boolean,
	/** A checkbox can be disabled. */
	disabled?: boolean,
	/** A checkbox can be different size. */
	size?: 'small' | 'tiny' | 'mini',
	/**
		Popup text or, react-intl coomponent or object of properties for Popup component.
		PROPS[IntlComponent=/language/localisation/, PopupProps=/components/modals/popup/]
	*/
	popup?: string | IntlComponent | PopupProps,
	/** Function called on checkbox or label click. */
	onClick?: (
		event: SyntheticEvent<>,
		data: {
			name: string,
			value: string,
			checked: boolean
		}
	) => void,
	/** Function called on checked state change. */
	onChange?: (
		event: SyntheticEvent<>,
		data: {
			name: string,
			value: string,
			checked: boolean
		}
	) => void
};

const DEFAULTS = {
	type: 'checkbox',
	toggle: false,
	radio: false,
	fitted: false,
	disabled: false,
	locked: false
};

/** COMPONENT BASED ON: https://react.semantic-ui.com/modules/checkbox/ */
const Component: React.AbstractComponent<Props, mixed> = React.memo<Props>((props: Props) => {
	// Called on checkbox click.
	const handleClick = (event: ?SyntheticEvent<>) => {
		if (typeof props.onClick === 'function') {
			props.onClick(event, handleCallbackData({ value: event.target.value, checked: event.target.checked }));
		}
	};

	// Called on checkbox change.
	const handleChange = (event: ?SyntheticEvent<>) => {
		if (typeof props.onChange === 'function') {
			props.onChange(event, handleCallbackData({ value: event.target.value, checked: event.target.checked }));
		}
	};

	// Handle data returned by callbacks.
	const handleCallbackData = (data: any) => {
		return {
			name: props.name,
			value: data.value,
			checked: data.checked
		};
	};

	// Assign classes
	const classes =
		props.type === 'radio'
			? classNames(
					'ui checkbox radio',
					{
						toggle: props.toggle,
						fitted: props.fitted,
						disabled: props.disabled,
						locked: props.locked,
						'has-description': props.description
					},
					props.size
			  )
			: classNames(
					{
						fitted: props.fitted,
						disabled: props.disabled,
						locked: props.locked,
						'has-description': props.description
					},
					props.size
			  );

	// Semantic UI React does not support uncontrolled checkboxes
	const prefix = props.type === 'radio' ? 'radio' : 'checkbox';
	const randID = `${prefix}-${Date.now()}-${Math.round(Math.random() * 9999)}`;

	let label = (
		<CheckboxLabel
			className={props.singleLine ? 'nowrap' : undefined}
			htmlFor={randID}
			text={props.label}
			description={props.description}
			image={props.image}
			icon={props.icon}
			flag={props.flag}
		/>
	);

	let common = {
		id: randID,
		className: classes,
		name: props.name,
		value: props.value,
		label: label,
		checked: props.checked,
		defaultChecked: props.defaultChecked,
		toggle: props.toggle,
		fitted: props.fitted,
		disabled: props.disabled || props.locked,
		onClick: handleClick,
		onChange: handleChange
	};

	let checkbox =
		props.type === 'radio' ? (
			<div className={classes} data-default={props.defaultChecked}>
				<input
					id={common.id}
					type='radio'
					className='hidden'
					name={common.name}
					value={common.value}
					defaultChecked={common.defaultChecked}
					checked={common.checked}
					disabled={common.disabled}
					onClick={common.onClick}
					onChange={common.onChange}
				/>
				{common.label}
			</div>
		) : (
			<Checkbox
				type='checkbox'
				indeterminate={props.indeterminate}
				data-default={props.defaultChecked}
				defaultIndeterminate={props.defaultIndeterminate}
				{...common}
			/>
		);

	if (props.popup) {
		checkbox =
			typeof props.popup === 'string' || React.isValidElement(props.popup) ? (
				<Popup trigger={checkbox} text={props.popup} />
			) : (
				<Popup trigger={checkbox} {...props.popup} />
			);
	}
	return checkbox;
});

// Documentation generation support
Component.displayName = 'Checkbox';
Component.defaultProps = DEFAULTS;

export default Component;
