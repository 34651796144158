/**
 * @prettier
 * @flow
 */

import classNames from 'classnames';
import { Responsive, Popup } from 'liana-ui/components/';
import { Table } from 'semantic-ui-react';
import type { IntlComponent } from 'react-intl';
import type { Props as PopupProps } from 'liana-ui/components/popup/Popup';

// prettier-ignore
type Props = {
	/** Cell content. */
	content?: string | React.Node,
	/** Represents colspan of cell. */
	colSpan?: number,
	/** A cell can be collapsing so that it only uses as much space as required. */
	collapsing: boolean,
	/** A cell can appear sorted. */
	sorted?: boolean,
	/** Represents width of cell. Possible values: 1 - 12 */
	width?: string | number,
	/** A table cell can adjust its text alignment. */
	textAlign?: 'left' | 'center' | 'right',
	/** A table cell can adjust its text alignment. */
	verticalAlign?: 'bottom' | 'middle' | 'top',
	/** Smallest device that cell will be displayed with. */
	minDevice?: 'mobile' | 'tablet' | 'computer' | 'largescreen' | 'widescreen',
	/** Largest device that cell will be displayed with. */
	maxDevice?: 'mobile' | 'tablet' | 'computer' | 'largescreen' | 'widescreen',
	/** Popup text or object of properties for Popup */
	popup?: IntlComponent | string | PopupProps
};

const DEFAULTS = {
	collapsing: false
};

/** COMPONENT BASED ON: https://react.semantic-ui.com/collections/table/ */
const Component: React.AbstractComponent<Props, mixed> = React.memo<Props>((props: Props) => {
	// Function to generate LianaUI Button
	const createCell = (props: Props) => {
		// Assign classes
		const classes = classNames({
			sorted: props.sorted
		});

		// Define cell
		let cell = (
			<Table.HeaderCell
				className={classes}
				collapsing={props.collapsing}
				colSpan={props.colSpan}
				width={props.width}
				textAlign={props.textAlign}
				verticalAlign={props.verticalAlign}
			>
				{props.content}
			</Table.HeaderCell>
		);

		// Display popup
		if (props.popup) {
			cell =
				typeof props.popup === 'string' || React.isValidElement(props.popup) ? (
					<Popup trigger={cell} text={props.popup} />
				) : (
					<Popup trigger={cell} {...props.popup} />
				);
		}

		return cell;
	};

	// Display reponsively
	let component =
		props.minDevice || props.maxDevice || props.hideTouch ? (
			<Responsive {...props}>{createCell(props)}</Responsive>
		) : (
			createCell(props)
		);

	return component;
});

// Documentation generation support
Component.displayName = 'TableFooterCell';
Component.defaultProps = DEFAULTS;

export type { Props };
export default Component;
