/**
 * @prettier
 * @flow
 */
import { useState } from 'react';

const AppContext = React.createContext(); // DEFAULT

type Props = {
	children: React.Node,
	value: any
};

const DEFAULTS = {
	ready: false,
	logout: false,
	settings: [],
	languages: [],
	countries: [],
	timezones: []
};

const AppContextProvider = (props: Props) => {
	let [state, setState] = useState(props.value || DEFAULTS);
	return <AppContext.Provider value={{ ...state, setState }}>{props.children}</AppContext.Provider>;
};

const AppContextConsumer = AppContext.Consumer;

export { AppContextProvider, AppContextConsumer };

export default AppContext;
