/**
 * @prettier
 * @flow
 */
import { useContext, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import { useLazyQuery } from '@apollo/client';
import Form from 'liana-ui/legacy/components/form/Form';
import { TopPanel, Search, PageHeader } from 'liana-ui/components';
import AppContext from 'context/AppContext';
import MeContext from 'context/MeContext';
import SidePanel from 'components/side-panel/SidePanel';
import NavigationData from 'components/side-panel/SidePanel.json';
import { useAuth0 } from '@auth0/auth0-react';
import { useMutation } from '@apollo/client';
import { DeleteSsoSession } from '../../graphql/Me.graphql';
import { GlobalSearch } from 'graphql/Search.graphql';

type Props = {
	active: boolean,
	children: React.Node
};

const Component = React.memo((props: Props) => {
	let appCtx = useContext(AppContext);
	let meCtx = useContext(MeContext);
	let pageHeaderContext = useContext(PageHeader.Context);
	const { logout } = useAuth0();
	const [deleteSsoSession] = useMutation(DeleteSsoSession);
	let classes = classNames('page-container app-ready', {
		index: props.active
	});
	const [searchLoading, setSearchLoading] = useState(false);
	const [searchResults, setSearchResults] = useState();

	/**
		 * todo
		 * Need to replace with new Gainsight keys for LianaAdmin only, currently those keys are for LianaAccount
		 * auth0 returns following:
			https://lianacloud.com/gainsightId : "1234asdasf2322131asasf123123"
			https://lianacloud.com/gainsightOrganizationId : 1
		 */

	const handleSearchChange = (event, data) => {
		if (!data.value) {
			setSearchResults(null);
		}
		setSearchLoading(true);
	};

	const handleSearchChangeDelay = (event, data) => {
		const searchWord = data.value;
		if (searchWord && searchWord.length >= data.minCharacters) {
			startSideSearch({
				variables: {
					solutionFilter: {
						or: [{ name: { contains: searchWord } }, { additionalName: { contains: searchWord } }]
					},
					accountFilter: { or: [{ name: { contains: searchWord } }, { email: { contains: searchWord } }] }
				}
			});
		}
	};

	const [startSideSearch] = useLazyQuery(GlobalSearch, {
		onCompleted: (data) => {
			const solutions = data.solutions?.nodes;
			const accounts = data.organizationAccounts?.nodes;
			let results = [];
			if (solutions) {
				solutions.map((sol) => {
					const additionalName = sol.additionalName ? ` (${sol.additionalName})` : '';
					results.push({
						title: sol.name + additionalName,
						description: (
							<>
								{sol.organizationName} | <FormattedMessage id='general.solutions' />
							</>
						),
						link: `/organizations/${sol.organizationId}/solutions/${sol.id}`
					});
				});
			}
			if (accounts) {
				accounts.map((acc) => {
					results.push({
						title: acc.name || acc.email,
						description: (
							<>
								{acc.organizationName} | <FormattedMessage id='general.users' />
							</>
						),
						link: `/organizations/${acc.organizationId}/users/${acc.id}`
					});
				});
			}
			setSearchResults(results);
			setSearchLoading(false);
		},
		onError: () => {
			setSearchResults([]);
			setSearchLoading(false);
		}
	});

	const getTools = () => {
		return (
			<Form>
				<Form.Field>
					<Search
						name='top-search'
						maxResults={5}
						minCharacters={3}
						submitUrl='/search'
						showMoreLink='/search'
						loading={searchLoading}
						results={searchResults}
						onSearchChange={handleSearchChange}
						onSearchChangeDelay={handleSearchChangeDelay}
					/>
				</Form.Field>
			</Form>
		);
	};

	const getSupport = () => ({
		site: {
			active: true,
			link: meCtx.supportSite
		}
	});

	const onLogout = () => {
		deleteSsoSession().then(
			() => {
				logout({ logoutParams: { returnTo: window.location.origin } });
			},
			() => {
				logout({ logoutParams: { returnTo: window.location.origin } });
			}
		);
	};

	let navigationData = JSON.parse(JSON.stringify(NavigationData));
	if (meCtx.adminRole === 'ADMIN' || meCtx.adminRole === 'SUPPORT' || meCtx.adminRole === 'SALES') {
		navigationData.push({
			icon: 'users',
			link: '/users',
			text: 'general.users'
		});
		if (meCtx.adminRole === 'ADMIN') {
			navigationData.push({
				icon: 'handshake',
				link: '/partners',
				text: 'general.partners'
			});
		}
	}

	return (
		<div className={classes}>
			<TopPanel
				logo={`${process.env.baseUrl}img/lianaadmin-logo.svg`}
				user={meCtx}
				applications={{ organizations: meCtx.organizations }}
				navigation={navigationData}
				tools={getTools()}
				support={getSupport()}
				onLogout={onLogout}
			/>
			<div className='content-wrapper' id='content-wrapper'>
				<SidePanel />
				<section className='main-content' id='main-content'>
					<PageHeader
						appName={appCtx.config.APP_NAME}
						breadcrumb={pageHeaderContext?.breadcrumb}
						header={pageHeaderContext?.header}
						actionHeader={pageHeaderContext?.actionHeader}
					/>
					{props.children}
				</section>
			</div>
		</div>
	);
});

Component.displayName = 'Main';
export default Component;
