/**
 * @prettier
 * @flow
 */

import classNames from 'classnames';
import { useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import InputNumber from 'rc-input-number';
import { Label, Popup } from 'liana-ui/components/';
import type { Props as PopupProps } from 'liana-ui/components/popup/Popup';

type Props = {
	/** A number input must have name. */
	name: string,
	/** Initial value for number input. Use for uncontrolled components only. */
	defaultValue?: number,
	/** Current value for number input. Use for controlled components only. */
	value?: number,
	/** A number input can have a placeholder text. */
	placeholder?: string,
	/** A number input can have minimum value. */
	min?: number,
	/** A number input can have maximum value. */
	max?: number,
	/** A number input can have different legal number intervals. */
	step?: number,
	/** A number input can display a measure of quantity (for ex. currency). */
	unit?: number,
	/** A number input can have precision of decimals. */
	precision?: number,
	/** An number input can take on the size of its container. */
	fluid?: boolean,
	/** A number input can be locked. */
	locked?: boolean,
	/** A number input can be disabled. */
	disabled?: boolean,
	/** A number input can be different size. */
	size?: 'small' | 'large',
	/**
		Popup text or, react-intl coomponent or object of properties for Popup component.
		PROPS[IntlComponent=/language/localisation/, PopupProps=/components/modals/popup/]
	*/
	popup?: string | IntlComponent | PopupProps,
	/** Function called when input number is changed. */
	onChange?: (data: {
		name: string,
		value: string
	}) => void
};

const DEFAULTS = {
	step: 1,
	precision: 0,
	fluid: false,
	locked: false,
	disabled: false
};

/** COMPONENT BASED ON: https://input-number.vercel.app */
const Component: React.AbstractComponent<Props, mixed> = React.memo<Props>((props: Props) => {
	const intl = useIntl();
	const inputWrapperRef = useRef();
	let sample = intl.formatNumber(1.45); // Get language decimal separator since ECMA-402 doesn't have an API for this

	let [internalValue, setInternalValue] = useState(props.value || props.defaultValue);
	let value = props.value === undefined ? internalValue : props.value;

	let handleChange = (value: number) => {
		setInternalValue(value);
		// Trigger Form onChange on value change
		if (inputWrapperRef && inputWrapperRef.current) {
			inputWrapperRef.current.querySelector('input').dispatchEvent(new Event('input', { bubbles: true }));
			inputWrapperRef.current.querySelector('input').dispatchEvent(new Event('change', { bubbles: true }));
		}
		if (typeof props.onChange === 'function') {
			props.onChange({
				name: props.name,
				value: value
			});
		}
	};

	let classes = classNames(
		'ui input number-input-wrapper',
		{
			fluid: props.fluid,
			locked: props.locked,
			disabled: props.disabled,
			'left labeled': props.unit
		},
		props.size
	);

	let input = (
		<div className={classes} ref={inputWrapperRef}>
			{props.unit ? <Label text={props.unit} /> : null}
			<InputNumber
				name={props.name}
				placeholder={props.placeholder}
				value={value}
				min={props.min}
				max={props.max}
				step={props.step}
				precision={props.precision}
				decimalSeparator={sample.charAt(1)}
				disabled={props.disabled}
				readOnly={props.locked}
				onChange={handleChange}
			/>
		</div>
	);

	// Display popup
	if (props.popup) {
		input =
			typeof props.popup === 'string' || React.isValidElement(props.popup) ? (
				<Popup trigger={input} text={props.popup} />
			) : (
				<Popup trigger={input} {...props.popup} />
			);
	}

	return input;
});

Component.displayName = 'NumberInput';
Component.defaultProps = DEFAULTS;

export type { Props };
export default Component;
