export const RESELLER =  {
  'KOODIVIIDAKKO': '1'
}

export const ROLE = {
  'ADMIN': 'ADMIN',
  'SUPPORT': 'SUPPORT',
  'SALES': 'SALES',
  'USER': 'USER',
  'SUPER_ADMIN': 'SUPER_ADMIN',
  'STAFF': 'STAFF'
}