/**
 * @prettier
 * @flow
 */

import { ListItem } from 'semantic-ui-react';
import { Image, Popup } from 'liana-ui/components/';
import ListList from './ListList';
import ListIcon from './ListIcon';
import ListContent from './ListContent';
import ListHeader from './ListHeader';
import ListDescription from './ListDescription';
import ListLink from './ListLink';
import type { Props as IconProps } from 'liana-ui/components/icon/Icon';
import type { Props as ImageProps } from 'liana-ui/components/image/Image';
import type { Props as ListItemProps } from 'liana-ui/components/list/src/ListItem';
import type { Props as PopupProps } from 'liana-ui/components/popup/Popup';

// prettier-ignore
type Props = {
	/** Transition support. */
	className?: string,
	/** Add item content. */
	content?: string | React.Node,
	/** Add item content floated to the right. */
	floatedContent?: string | React.Node,
	/** Item can have a header as text */
	header?: string | React.Node,
	/** Name or props object for Icon. */
	icon?: string | IconProps,
	/** Name or props object for Image. */
	image?: string | ImageProps,
	/** Item can be a link. Opens absolute links in new browser tab and internal links via router. */
	link?: string,
	/** Array of props for ListList. */
	items?: Array<ListItemProps>,
	/** Props object for Popup */
	popup?: FormattedMessage | string | PopupProps,
	/** List item can be clicked. */
	onClick?: (
		event: SyntheticEvent<>,
		data: any
	) => void
};

/** COMPONENT BASED ON: https://react.semantic-ui.com/elements/list/ */
const Component: React.AbstractComponent<Props, mixed> = React.memo<Props>((props: Props) => {
	let image = props.image,
		icon = props.icon;

	// Assign Image props
	if (typeof image === 'string') {
		image = { src: image };
	} else {
		image = props.image;
	}

	// Assign Icon props
	if (typeof icon === 'string') {
		icon = { name: icon };
	}

	let item = (
		<ListItem className={props.className}>
			{props.floatedContent ? <ListContent floated='right'>{props.floatedContent}</ListContent> : null}
			{image ? <Image avatar {...image} /> : null}
			{icon ? <ListIcon verticalAlign={props.header && props.content ? 'middle' : 'top'} {...icon} /> : null}
			{icon || image || props.header ? (
				<ListContent>
					{props.header ? (
						<ListHeader
							link={props.link}
							onClick={props.onClick ? props.onClick : null}
							popup={props.popup}
						>
							{props.header}
						</ListHeader>
					) : null}
					{props.header && props.content ? (
						<ListDescription>{props.content}</ListDescription>
					) : props.link || props.onClick ? (
						<ListLink
							link={props.link}
							linkTarget={props.linkTarget}
							onClick={props.onClick}
							popup={props.popup}
						>
							{props.content}
						</ListLink>
					) : (
						props.content
					)}
				</ListContent>
			) : props.link || props.onClick ? (
				<ListLink link={props.link} linkTarget={props.linkTarget} onClick={props.onClick} popup={props.popup}>
					{props.content}
				</ListLink>
			) : (
				props.content
			)}
			{props.items ? <ListList items={props.items} /> : null}
		</ListItem>
	);

	// Display popup
	if (props.popup && !props.link && !props.onClick) {
		item =
			typeof props.popup === 'string' ? (
				<Popup trigger={item} text={props.popup} />
			) : (
				<Popup trigger={item} {...props.popup} />
			);
	}

	return item;
});

// Documentation generation support
Component.displayName = 'ListItem';

export type { Props };
export default Component;
