/**
 * @prettier
 * @flow
 */

import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import Validate from 'liana-ui/definitions/component/Validate';
import { Segment } from 'semantic-ui-react';
import SegmentGroup from './SegmentGroup';
import SegmentInline from './SegmentInline';
import { Popup, Responsive, Loader } from 'liana-ui/components/';
import type { IntlComponent } from 'react-intl';
import type { Props as PopupProps } from 'liana-ui/components/popup/Popup';

type RemoveType =
	| 'all'
	| 'horizontal'
	| 'vertical'
	| 'top'
	| 'right'
	| 'bottom'
	| 'left'
	| Array<'top' | 'right' | 'bottom' | 'left'>;

// prettier-ignore
type Props = {
	/** Segment content as children. */
	children: React.Node,
	/** A segment can have the id attribute */
	id?: string,
	/** Segment can be a link. Opens absolute links in new browser tab and internal links via router. */
	link?: string,
	/** A basic segment has no special formatting. */
	basic?: boolean,
	/** A segment can be formatted to raise above the page. */
	raised?: boolean,
	/** A segment can be formatted to lower below the page. */
	lowered?: boolean,
	/** A segment can be formatted to be selected. */
	selected?: boolean,
	/** A segment can be formatted to contain error. */
	error?: boolean,
	/** A segment can be formatted to appear less noticeable. */
	secondary?: boolean,
	/** A segment can be formatted to appear viewed. */
	viewed?: boolean,
	/** A segment can have its colors inverted for contrast. */
	inverted?: boolean,
	/** A segment content area can be more noticable. */
	joined?: boolean,
	/** A segment can be hoverable. */
	hoverbale?: boolean,
	/** A segment can show its content is unable to be interacted with. */
	disabled?: boolean,
	/** A segment can show its content is unnecessary to be interacted with. */
	off?: boolean,
	/** A segment can be locked to indicate that the content is in use but can not be edited. */
	locked?: boolean,
	/** A segment can show its content is loading. Use 'scrolling' to make loader scroll with users screen to keep it always visible. */
	loading?: boolean | 'scrolling',
	/** A segment can have different background textures. */
	background?: 'check' | 'grid' | 'upsell',
	/** A segment can have different color. */
	color?: 'blue' | 'green' | 'yellow' | 'red',
	/** A segment text can be aligned. */
	textAlign?: 'left' | 'center' | 'right',
	/** A streched segment can specify its vertical alignment to have all content vertically aligned. */
	verticalAlign?: 'middle',
	/** A segment can format content to be aligned vertically. */
	vertical?: boolean,
	/** A segment can be compressed to have less padding. */
	compressed?: boolean | 'very',
	/** A segment can increase its padding. */
	padded?: boolean | 'very',
	/** A segment can take up only as much space as is necessary for content. */
	compact?: boolean | 'very',
	/** A segment can be totally squared without any border radius. */
	squared?: boolean,
	/** A segment can indicate it contains multiple stacked segments. */
	stacked?: boolean,
	/** A segment can be used to reserve space for conditionally displayed content. */
	placeholder?: boolean,
	/** A segment can be attached to other content. */
	attached?: boolean | 'top' | 'bottom',
	/**
		A segment margins can be removd around the segment.
		VALUES['all' | 'horizontal' | 'vertical' | 'top' | 'right' | 'bottom' | 'left']
	*/
	removeMargins?: string | Array<string>,
	/**
		A segment paddings can be removd around the segment.
		VALUES['all' | 'horizontal' | 'vertical' | 'top' | 'right' | 'bottom' | 'left']
	*/
	removePaddings?: string | Array<string>,
	/** A segment can scroll overflowing content horizontally. */
	scrollOverflow?: boolean | 'x' | 'y',
	/** A segment can have additional classes. Use for very special features only! */
	className?: string,
	/** A segment can be different size. */
	size?: 'small' | 'large',
	/** Smallest device that component will be displayed with. */
	minDevice?: 'mobile' | 'tablet' | 'computer' | 'largescreen' | 'widescreen',
	/** Largest device that component will be displayed with. */
	maxDevice?: 'mobile' | 'tablet' | 'computer' | 'largescreen' | 'widescreen',
	/** Hide content on touch devices */
	hideTouch?: boolean,
	/**
		Popup text or, react-intl coomponent or object of properties for Popup component.
		PROPS[IntlComponent=/language/localisation/, PopupProps=/components/modals/popup/]
	*/
	popup?: string | IntlComponent | PopupProps,
	/** Function called on segment click. */
	onClick?: (
		event: SyntheticEvent<>
	) => void
};

const ELEMENT_SIDES = ['top', 'right', 'bottom', 'left', 'horizontal', 'vertical'];

const DEFAULTS = {
	basic: false,
	raised: false,
	lowered: false,
	selected: false,
	error: false,
	secondary: false,
	viewed: false,
	inverted: false,
	joined: false,
	hoverable: false,
	disabled: false,
	off: false,
	locked: false,
	loading: false,
	compressed: false,
	vertical: false,
	padded: false,
	compact: false,
	squared: false,
	stacked: false,
	placeholder: false,
	attached: false,
	scrollOverflow: false,
	hideTouch: false
};

/** COMPONENT BASED ON: https://react.semantic-ui.com/elements/segment/ */
const Component: React.AbstractComponent<Props, mixed> = React.memo<Props>((props: Props) => {
	const navigate = useNavigate();
	// Get link type
	let linkType = Validate.linkType(props.link);

	const getElementClasses = (data: RemoveType, type: 'margin' | 'padding') => {
		let result = {},
			name = '';

		if (data) {
			if (Array.isArray(data) && data.length > 0) {
				for (let i = 0; i < ELEMENT_SIDES.length; i++) {
					name =
						ELEMENT_SIDES[i] === 'horizontal' || ELEMENT_SIDES[i] === 'vertical'
							? `remove-${ELEMENT_SIDES[i]}-${type}s`
							: `remove-${ELEMENT_SIDES[i]}-${type}`;
					result[name] = data.includes(ELEMENT_SIDES[i]);
				}
			} else if (typeof data === 'string') {
				if (data === 'all') {
					name = `remove-${type}s`;
					result[name] = true;
				} else if (data === 'horizontal' || data === 'vertical') {
					name = `remove-${data}-${type}s`;
					result[name] = true;
				} else if (data === 'top' || data === 'right' || data === 'bottom' || data === 'left') {
					name = `remove-${data}-${type}`;
					result[name] = true;
				}
			}
		}
		return result;
	};

	const handleClick = (event: SyntheticEvent<HTMLButtonElement>) => {
		// Trigger onClick callback funtion
		if (typeof props.onClick === 'function') {
			event.preventDefault();
			props.onClick(event);
		}

		// Trigger internal link
		if (linkType && linkType === 'internal') {
			event.preventDefault();
			if (linkType === 'internal') {
				navigate(props.link);
			}
		}
	};

	// Function to generate LianaUI Segment
	const createSegment = (props: Props) => {
		// Assign classes
		let classes = classNames(
			{
				off: props.off,
				locked: props.locked,
				lowered: props.lowered,
				hoverable: props.onClick || props.link || props.hoverable,
				selected: props.selected,
				error: props.error,
				viewed: props.viewed,
				joined: props.joined,
				very: props.compressed === 'very',
				compressed: props.compressed,
				squared: props.squared,
				'middle-aligned': props.verticalAlign === 'middle',
				'background-check': props.background === 'check',
				'background-grid': props.background === 'grid',
				'background-upsell': props.background === 'upsell',
				'overflow-scroll': props.scrollOverflow && typeof props.scrollOverflow !== 'string',
				'overflow-scroll-y': props.scrollOverflow === 'y',
				'overflow-scroll-x': props.scrollOverflow === 'x'
			},
			getElementClasses(props.removeMargins, 'margin'),
			getElementClasses(props.removePaddings, 'padding'),
			props.className
		);

		// Define Segment
		let segment = (
			<Segment
				as={props.onClick || props.link || props.hoverable ? 'a' : undefined}
				id={props.id}
				href={props.link ? props.link : props.onClick || props.hoverable ? '#' : undefined}
				target={linkType === 'external' ? '_blank' : undefined}
				rel={linkType === 'external' ? 'noopener noreferrer' : undefined}
				className={classes}
				basic={props.basic}
				raised={props.raised}
				inverted={Boolean(props.inverted || props.background === 'check' || props.color)}
				color={props.color}
				secondary={props.secondary}
				textAlign={props.textAlign}
				loading={props.loading === true}
				vertical={props.vertical}
				padded={props.padded}
				compact={props.compact}
				stacked={props.stacked}
				placeholder={props.placeholder}
				attached={props.attached}
				size={props.size}
				onClick={handleClick}
			>
				{props.loading === 'scrolling' ? <Loader active dimmer /> : null}
				{props.children}
			</Segment>
		);

		// Display popup
		return Popup.attach(props.popup, segment);
	};

	// Display reponsively
	let component =
		props.minDevice || props.maxDevice || props.hideTouch ? (
			<Responsive {...props}>{createSegment(props)}</Responsive>
		) : (
			createSegment(props)
		);

	return component;
});

// Documentation generation support
Component.displayName = 'Segment';
Component.defaultProps = DEFAULTS;

// Attach Subcomponents
Component.Group = SegmentGroup;
Component.Inline = SegmentInline;

export type { Props };
export default Component;
