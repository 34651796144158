/**
 * @prettier
 * @flow
 */

import { FormattedDate, FormattedTime } from 'react-intl';
import { Text } from 'liana-ui/components/';

type Props = {
	/** Date/time value to display. */
	value: string,
	/** Time zone name. Default is users timezone. */
	timezone?: string
};

const DEFAULTS = {};

/** COMPONENT BASED ON: https://formatjs.io/docs/react-intl */
const Component: React.AbstractComponent<Props, mixed> = React.memo<Props>((props: Props) => {
	let component = (
		<span>
			<FormattedDate
				value={props.value}
				timeZone={props.timezone ? props.timezone : undefined}
				weekday='short'
				day='numeric'
				month='short'
				year='numeric'
			/>
			<br />
			<Text size='small' color='grey'>
				<FormattedTime value={props.value} timeZone={props.timezone ? props.timezone : undefined} />
			</Text>
		</span>
	);

	return component;
});

// Documentation generation support
Component.displayName = 'Time';
Component.defaultProps = DEFAULTS;

export type { Props };
export default Component;
