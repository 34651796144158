/**
 * @prettier
 * @flow
 */

type DateData = {
	day: string,
	month: string,
	year: string,
	hour: string,
	minute: string
};

const RANGE_SECONDS = {
	hours: 3600000,
	days: 86400000,
	weeks: 604800000,
	months: 2419200000
};

export default class DateUtils {
	constructor() {}
	static isValidDate = (date: string) => {
		if (
			typeof date === 'string' &&
			(date.match(/^(\d{4})-(\d{2})-(\d{2})T(\d{2})\:(\d{2})\:(\d{2})(\+|-)(\d{2})\:(\d{2})$/) || // ISO 8601
			date.match(/^(\d{4})-(\d{2})-(\d{2}) (\d{2})\:(\d{2})\:(\d{2})(\+|-)(\d{2})$/) || // ISO 8601/SQL standard format (without T and :00)
				date.match(/^(\d{4})-(\d{2})-(\d{2}) (\d{2})\:(\d{2})\:(\d{2})(\+|-)(\d{2})\:(\d{2})$/)) // ISO 8601/SQL standard format (without T but with :45 etc.)
		) {
			return true;
		}
		return false;
	};
	static formatISO = (date: string) => {
		// Format ISO 8601/SQL standard format (without T and :00) to official ISO 8061 format
		if (date) {
			if (date.match(/^(\d{4})-(\d{2})-(\d{2}) (\d{2})\:(\d{2})\:(\d{2})(\+|-)(\d{2})$/)) {
				date = date.replace(' ', 'T') + ':00';
			}
			// Format ISO 8601/SQL standard format (without T but with :45 etc.) to official ISO 8061 format
			if (date.match(/^(\d{4})-(\d{2})-(\d{2}) (\d{2})\:(\d{2})\:(\d{2})(\+|-)(\d{2})\:(\d{2})$/)) {
				date = date.replace(' ', 'T');
			}
		}

		return date;
	};
	static getNextDate = (date: string, type: 'hours' | 'days' | 'weeks' | 'months') => {
		let now = new Date(date);
		switch (type) {
			case 'hours':
				now.setHours(now.getHours() + 1);
				break;
			case 'days':
				now.setDate(now.getDate() + 1);
				break;
			case 'weeks':
				now.setDate(now.getDate() + 7);
				break;
			case 'months':
				now.setMonth(now.getMonth() + 1);
				break;
		}
		return new Date(now.getTime()).toISOString();
	};
	static parseDateData(dateStr: string, part?: 'hour' | 'minute' | 'day' | 'month' | 'year') {
		let dateTime = dateStr.split('T'),
			date = dateTime[0].split('-'),
			time = dateTime[1].split(':');
		if (typeof part === 'string') {
			switch (part) {
				case 'hour':
					return time[0];
				case 'minute':
					return time[1];
				case 'day':
					return date[2];
				case 'month':
					return date[1];
				case 'year':
					return date[0];
			}
		}
		return {
			day: date[2],
			month: date[1],
			year: date[0],
			hour: time[0],
			minute: time[1]
		};
	}
	static checkIncomplete(curTime: string, tgtTime: string, range: 'hours' | 'days' | 'weeks' | 'months') {
		let current: DateData = (DateUtils.parseDateData(curTime): any),
			target: DateData = (DateUtils.parseDateData(tgtTime): any);

		switch (range) {
			case 'hours':
				return (
					target.hour === current.hour &&
					target.day === current.day &&
					target.month === current.month &&
					target.year === current.year
				);
			case 'days':
				return target.day === current.day && target.month === current.month && target.year === current.year;
			case 'weeks':
				return target.day >= current.day && target.month >= current.month && target.year >= current.year;
			case 'months':
				return target.month === current.month && target.year === current.year;
		}
		return false;
	}
	static getDisplayRange(val: number) {
		for (let key in RANGE_SECONDS) {
			if (val >= RANGE_SECONDS.months) {
				return 'months';
			} else if (val === RANGE_SECONDS[key]) {
				return key;
			}
		}
		return '';
	}
	static getRangeSeconds(key: 'hours' | 'days' | 'weeks' | 'months') {
		return key in RANGE_SECONDS ? RANGE_SECONDS[key] : 0;
	}
}
