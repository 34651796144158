/**
 * @prettier
 */
import { useState, useContext, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { useLazyQuery } from '@apollo/client';
import Form from 'liana-ui/legacy/components/form/Form';
import { SidePanel, Search, Button } from 'liana-ui/components';
import AppContext from 'context/AppContext';
import MeContext from '../../context/MeContext';
import Data from './SidePanel.json';
import { GlobalSearch } from 'graphql/Search.graphql';
import { InstallSolutionModal } from '@lianacloud/ui-common/dist';
import { RESELLER, ROLE } from '../../definitions/enums';

let Component = () => {
	const appCtx = useContext(AppContext);
	const meCtx = useContext(MeContext);
	const baseUrl = appCtx.config.BASE_URL || 'https://base.127.0.0.1.nip.io';
	const [modalOpen, setModalOpen] = useState(false);
	const [searchLoading, setSearchLoading] = useState(false);
	const [searchResults, setSearchResults] = useState([]);

	let sidePanelData = JSON.parse(JSON.stringify(Data));
	if (meCtx.adminRole === 'ADMIN' || meCtx.adminRole === 'SUPPORT' || meCtx.adminRole === 'SALES') {
		sidePanelData.push({
			icon: 'users',
			link: '/users',
			text: 'general.users'
		});
		if (meCtx.adminRole === 'ADMIN' || meCtx.adminRole === 'SUPPORT') {
			sidePanelData.push({
				icon: 'handshake',
				link: '/partners',
				text: 'general.partners'
			});
		}
		if (
			(meCtx.adminRole === ROLE.ADMIN || meCtx.adminRole === ROLE.SUPPORT) &&
			meCtx.selectedOrganizationReseller === RESELLER.KOODIVIIDAKKO
		) {
			sidePanelData.push(
				...[
					{
						divider: true
					},
					{
						icon: 'wrench',
						link: '/support-tools',
						text: 'menu.support_tools.name'
					}
				]
			);
		}
	}

	const handleSearchChange = (event, data) => {
		setSearchLoading(true);
	};

	let searchTimeout = useRef();
	const handleSearchChangeDelay = (event, data) => {
		const searchWord = data.value;
		if (!searchWord) {
			setSearchResults(null);
		}
		if (searchWord && searchWord.length >= data.minCharacters) {
			clearTimeout(searchTimeout.current);
			searchTimeout.current = setTimeout(() => {
				startSideSearch({
					variables: {
						solutionFilter: {
							or: [{ name: { contains: searchWord } }, { additionalName: { contains: searchWord } }]
						},
						accountFilter: {
							or: [{ name: { contains: searchWord } }, { email: { contains: searchWord } }]
						},
						organizationFilter: { name: { contains: searchWord } }
					}
				});
			}, 1000);
		}
	};

	const getTools = () => {
		return (
			<Form>
				<Form.Field>
					<Search
						key='side-panel-search'
						name='side-search'
						maxResults={5}
						minCharacters={3}
						submitUrl='/search'
						showMoreLink='/search'
						loading={searchLoading}
						results={searchResults}
						onSearchChange={handleSearchChange}
						onSearchChangeDelay={handleSearchChangeDelay}
					/>
				</Form.Field>
				{meCtx.adminRole && meCtx.adminRole !== 'USER' ? (
					<Form.Field>
						<Button
							fluid
							icon='fa-add'
							text={<FormattedMessage id='general.install_solution' />}
							onClick={() => setModalOpen(true)}
						/>
					</Form.Field>
				) : (
					<></>
				)}
			</Form>
		);
	};

	const [startSideSearch] = useLazyQuery(GlobalSearch, {
		onCompleted: (data) => {
			const solutions = data.solutions?.nodes;
			const accounts = data.organizationAccounts?.nodes;
			const organizations = data.organizations?.nodes;
			let results = [];
			if (solutions && solutions.length > 0) {
				solutions.map((sol) => {
					const additionalName = sol.additionalName ? ` (${sol.additionalName})` : '';
					results.push({
						key: 'solution-' + sol.id,
						title: sol.name + additionalName,
						description: (
							<>
								<FormattedMessage id='general.solutions' /> | {sol.organizationName}
							</>
						),
						link: `/organizations/${sol.organizationId}/solutions/${sol.id}`
					});
				});
			}
			if (accounts && accounts.length > 0) {
				accounts.map((acc) => {
					results.push({
						key: 'account-' + acc.id,
						title: acc.name || acc.email,
						description: (
							<>
								<FormattedMessage id='general.users' /> | {acc.organizationName}
							</>
						),
						link: `/organizations/${acc.organizationId}/users/${acc.id}`
					});
				});
			}
			if (organizations && organizations.length > 0) {
				organizations.map((org) => {
					results.push({
						key: 'organization-' + org.id,
						title: org.name,
						description: (
							<>
								<FormattedMessage id='general.organizations' /> | {org.name}
							</>
						),
						link: `/organizations/${org.id}`
					});
				});
			}
			setSearchResults(results);
			setSearchLoading(false);
		},
		onError: () => {
			setSearchResults([]);
			setSearchLoading(false);
		}
	});

	return (
		<>
			<SidePanel tools={getTools()} navigation={sidePanelData} />
			<InstallSolutionModal
				open={modalOpen}
				onClose={() => {
					setModalOpen(false);
				}}
				iframeLink={baseUrl}
			/>
		</>
	);
};

Component.displayName = 'SidePanel';

export default Component;
