/**
 * @prettier
 * @flow
 */

import { FormattedDate, FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { Dropdown } from 'semantic-ui-react';
import { Text, Icon, Divider, Segment, Message, Header, Label, Popup, Visibility } from 'liana-ui/components/';
import type { Props as PopupType } from 'liana-ui/components/popup/Popup';

// prettier-ignore
type Props = {
	/** If is open */
	open: boolean,
	/** Notification menu data. Array of notification objects: DATA[json/notifications/notifications.json] */
	notifications?: Array<{ title: string, id: string, publishTime: string, ... }>,
	/** Position, with and heigh of dropdown menu */
	style: { [key: string]: string },
	/** Object of properties for Popup */
	popup?: PopupType,
	/** Called on menu open. */
	onOpen?: (
		event: SyntheticEvent<>,
		data: {
			id: string
		}
	) => void,
	/** Called on menu close. */
	onClose?: (
		event: SyntheticEvent<>,
		data: {
			id: string,
			viewed: Array<string>
		}
	) => void
};

const DEFAULTS = {
	open: false
};

const ID = 'notification';
const AMOUNT = 5;

/** COMPONENT BASED ON: https://react.semantic-ui.com/modules/dropdown/ and https://react.semantic-ui.com/collections/menu/ */
const Component: React.AbstractComponent<Props, mixed> = React.memo<Props>((props: Props) => {
	// Render nothing if empty
	if (!props.notifications) {
		return null;
	}

	let viewed = [];

	const isMobile =
		document.querySelector('html').classList.contains('mobile') ||
		document.querySelector('html').classList.contains('tablet');

	const handleOpen = (event: ?SyntheticEvent<>, data: any) => {
		if (typeof props.onOpen === 'function') {
			props.onOpen(event, handleCallbackData(data));
		}
	};

	const handleClose = (event: ?SyntheticEvent<>, data: any) => {
		if (typeof props.onClose === 'function') {
			props.onClose(event, handleCallbackData(data));
		}
	};

	const handleViewed = (id) => {
		if (props.open) {
			viewed.push(id);
		}
	};

	// Handle data returned callback.
	const handleCallbackData = (data: any) => ({
		id: data.id
	});

	const getAllUnviewed = () => {
		let unviewed = 0;
		props.notifications.map((notification) => {
			unviewed += notification.viewed ? 0 : 1;
		});
		return unviewed;
	};

	const getRestUnviewed = () => {
		let unviewed = 0;
		props.notifications.map((notification, i) => {
			if (i >= AMOUNT) {
				unviewed += notification.viewed ? 0 : 1;
			}
		});
		return unviewed;
	};

	const getLabels = (notification) => {
		let labels = [
			{
				text: (
					<FormattedDate
						value={notification.publish_time}
						weekday='short'
						day='numeric'
						month='short'
						year='numeric'
					/>
				)
			}
		];
		if (!notification.viewed) {
			labels.push({
				text: <FormattedMessage id='component.notification-menu.new' />,
				circular: true,
				notification: true
			});
		}
		return labels;
	};

	const getNotifications = () => {
		let notifications = [];
		if (props.notifications.length > 0) {
			props.notifications.map((notification) => {
				if (notifications.length < AMOUNT) {
					notifications.push(
						<>
							<Dropdown.Item
								as={Link}
								className={notification.viewed ? 'viewed' : undefined}
								to={`/notifications#notification${notification.id}`}
							>
								<Visibility onTopVisible={() => handleViewed(notification.id)}>
									<Header as='h4' text={notification.title} />
									<p>
										<Label.Group labels={getLabels(notification)} />
									</p>
									<p>{notification.summary}</p>
								</Visibility>
							</Dropdown.Item>
							<Divider removeMargins='all' />
						</>
					);
				}
			});

			if (props.notifications.length > AMOUNT) {
				notifications.push(
					<Dropdown.Item
						as={Link}
						icon='arrow right'
						to='notifications'
						content={
							<span>
								<FormattedMessage id='component.notification-menu.view_all' />
								{unviewedRest ? (
									<span>
										&nbsp;
										<Label size='tiny' notification text={unviewedRest} circular blinking />
									</span>
								) : null}
							</span>
						}
					/>
				);
			}
		} else {
			notifications.push(
				<Segment basic>
					<Message
						info
						content={<FormattedMessage id='component.notification-menu.noneFoundContent' />}
						icon='fa-circle-info'
					/>
				</Segment>
			);
		}
		return notifications;
	};

	let unviewedAll = getAllUnviewed();
	let unviewedRest = getRestUnviewed();

	let menu = (
		<Dropdown
			id={`${ID}Menu`}
			tabIndex={false}
			closeOnBlur={false}
			open={props.open}
			item
			icon={
				<Icon
					name={isMobile && props.open ? 'fa-close' : 'fa-bell'}
					size={isMobile && props.open ? undefined : 'large'}
					circular={isMobile && props.open ? true : false}
					notification={unviewedAll}
				/>
			}
			onOpen={handleOpen}
			onClose={handleClose}
		>
			<Dropdown.Menu id={`${ID}Dropdown`} style={props.style}>
				{props.open ? getNotifications() : null}
			</Dropdown.Menu>
		</Dropdown>
	);

	// Display popup
	menu = !props.open ? (
		<Popup trigger={menu} text={<FormattedMessage id='component.notification-menu.name' />} {...props.popup} />
	) : (
		menu
	);

	return menu;
});

// Documentation generation support
Component.displayName = 'NotificationMenu';
Component.defaultProps = DEFAULTS;

export type { Props };
export default Component;
