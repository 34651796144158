/**
 * @prettier
 * @flow
 */

import { injectIntl, FormattedMessage } from 'react-intl';
import Modal from 'liana-ui/legacy/components/modal/Modal';
import Button from 'liana-ui/components/button/Button';
import Message from 'liana-ui/components/message/Message';

export type Translations = {
	header: string,
	description: string,
	close: string,
	redirect: string
};

type Props = {
	intl: Intl,
	/** Optional custom texts for prompt (header. descriptio, close, redirect) */
	translations: Translations,
	/** Callback when clicking 'Save' button */
	onSave: () => mixed,
	/** Callback when clicking 'Leave' button */
	onLeave: () => mixed
};

/** COMPONENT BASED ON: https://fomantic-ui.com/modules/modal.html */
class FormActionsPrompt extends React.PureComponent<Props> {
	translations: Translations;
	_prompt: { current: React.ElementRef<typeof Modal> | null };

	constructor(props: Props) {
		super(props);
		this._prompt = React.createRef();
	}

	// Public function
	show(): void {
		if (this._prompt.current) {
			this._prompt.current.show();
		}
	}

	// Public function
	hide(): void {
		if (this._prompt.current) {
			this._prompt.current.hide();
		}
	}

	render() {
		let saving = typeof this.props.onSave === 'function';
		return (
			<Modal
				classes='tiny'
				ref={this._prompt}
				show={false}
				settings={{
					closable: false
				}}
				header={<FormattedMessage id='component.form-actions.prompt.header' />}
				actions={
					<>
						<Button
							key='cancel'
							icon='ban'
							text={<FormattedMessage id='component.form-actions.prompt.cancel' />}
							type='cancel'
							onClick={this.props.onLeave}
						/>
						<Button
							key='save'
							icon={saving ? 'checkmark' : null}
							text={
								<FormattedMessage
									id={
										saving
											? 'component.form-actions.prompt.save'
											: 'component.form-actions.prompt.stay'
									}
								/>
							}
							type='cancel'
							color='primary'
							onClick={this.props.onSave}
						/>
					</>
				}
			>
				<Message
					warning
					layout='big'
					vertical
					header={
						<FormattedMessage
							id={
								saving
									? 'component.form-actions.prompt.saveHeader'
									: 'component.form-actions.prompt.stayHeader'
							}
						/>
					}
					content={<FormattedMessage id='component.form-actions.prompt.description' />}
				/>
			</Modal>
		);
	}
}

let Wrapper = injectIntl(FormActionsPrompt, { forwardRef: true });
Wrapper.displayName = 'FormActionsPrompt';
export default Wrapper;
