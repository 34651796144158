/**
 * @prettier
 * @flow
 */

import classNames from 'classnames';
import { useState } from 'react';
import { Header } from 'semantic-ui-react';
import { Icon, Image, List, Label, Checkbox, Popup, Responsive } from 'liana-ui/components/';
import type { IntlComponent } from 'react-intl';
import type { Props as IconProps } from 'liana-ui/components/icon/Icon';
import type { Props as ImageProps } from 'liana-ui/components/image/Image';
import type { Props as CheckboxProps } from 'liana-ui/components/checkbox/Checkbox';
import type { Props as LabelProps } from 'liana-ui/components/label/Label';
import type { Props as LimitLabelProps } from 'liana-ui/components/label/LimitLabel';
import type { Props as PopupProps } from 'liana-ui/components/popup/Popup';

// prettier-ignore
type Props = {
	/**
		A header can have text.
		PROPS[IntlComponent=/localization/]
	*/
	text?: string | IntlComponent,
	/** A header can have sub header text or content. */
	subheader?: string | React.Node,
	/** A header can have sub info text or content. */
	subinfo?: string | React.Node | Array<React.Node>,
	/** 
	 	A header can have a help popop with help icon. 
	 	PROPS[IntlComponent=/language/localisation/, PopupProps=/components/modals/popup/]
	*/
	help?: string | IntlComponent | PopupProps,
	/** A header can have content floated to the right. */
	floatedContent?: React.Node,
	/** A header can be rendered as fifferent HTML elements. */
	as?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5',
	/** A header can have an id. */
	id?: string,
	/**
		A header can have an icon.
		PROPS[IconProps=/components/labels/icons/icon]
	*/
	icon?: string | IconProps,
		/**
		A header can have an icon group.
		PROPS[IconGroupProps=/components/labels/icons/icon-group]
	*/
	iconGroup?: IconGroupProps,
	/**
		A header can have an upsell icon after the content
	*/
	upsell?: boolean,
	/**
		A header can have an image.
		PROPS[ImageProps=/components/texts/image/]
	*/
	image?: string | ImageProps,
	/** A header may appear inline. */
	inline?: boolean,
	/** A header can have a chevron to indiate expanding content. */
	chevron?: boolean | 'down' | 'up',
	/**
		A header can have labels and/or limit label.
		PROPS[LabelProps=/components/labels/labels/label/, LimitLabelProps=/components/labels/limit-label/]
	*/
	labels?:
		LabelProps
		| Array<LabelProps>
		| LimitLabelProps,
	/**
		A header can have a checkbox, radio or toggle.
		PROPS[/components/forms/checkbox]
	*/
	checkbox?: CheckboxProps,
	/** A header can divide from the content below it. */
	dividing?: boolean,
	/** A header can have uppercase text. */
	uppercase?: boolean,
	/** A header can hyphenate text. */
	hyphenate?: boolean,
	/** A header can stay single line and display overlow as ellipsis. */
	singleLine?: boolean,
	/** A header can vertically align content. */
	textAlign?: 'left' | 'center',
	/** Content headings are sized with em and are based on the font-size of their container. */
	size?: 'tiny' | 'small' | 'large' | 'huge' | 'massive',
	/** Smallest device that component will be displayed with. */
	minDevice?: 'mobile' | 'tablet' | 'computer' | 'largescreen' | 'widescreen',
	/** Largest device that component will be displayed with. */
	maxDevice?: 'mobile' | 'tablet' | 'computer' | 'largescreen' | 'widescreen',
	/** Function called on expanding chevron click. */
	onClick?: (
		event: SyntheticEvent<>,
		data: {
			chevron: 'down' | 'up'
		}
	) => void
};

const DEFAULTS = {
	as: 'div',
	dividing: false,
	inline: false,
	singleLine: false
};

/** COMPONENT BASED ON: https://react.semantic-ui.com/elements/header/ */
const Component: React.AbstractComponent<Props, mixed> = React.memo<Props>((props: Props) => {
	// Internal states
	let [internalChevron, setInternalChevron] = useState('down');
	let chevron = typeof props.chevron !== 'string' ? internalChevron : props.chevron;

	const handleClick = (event: ?SyntheticEvent<>) => {
		event.preventDefault();

		if (props.chevron && typeof props.chevron !== 'string') {
			setInternalChevron(chevron === 'up' ? 'down' : 'up');
		}

		// Trigger onClick callback funtion
		if (typeof props.onClick === 'function') {
			props.onClick(event, { chevron: chevron === 'up' ? 'down' : 'up' });
		}
	};

	// Function to generate LianaUI Header
	const createHeader = (props: Props) => {
		// Assign classes
		let classes = classNames({
			inline: props.inline,
			'text-uppercase': props.uppercase,
			'has-subheader': props.subheader,
			'has-subinfo': props.subinfo,
			'text-hyphenate': props.hyphenate,
			'single-line': props.singleLine,
			'chevron-up': props.chevron && chevron === 'up',
			'chevron-down': props.chevron && chevron === 'down'
		});

		let content =
			props.icon ||
			props.iconGroup ||
			props.upsell ||
			props.iconGroup ||
			props.chevron ||
			props.image ||
			props.checkbox ? (
				<Header.Content key='content'>{getContent()}</Header.Content>
			) : (
				getContent()
			);

		return (
			<Header
				id={props.id}
				className={classes}
				as={props.chevron ? 'a' : props.as}
				href={props.chevron ? '#' : undefined}
				dividing={props.dividing}
				textAlign={props.textAlign}
				size={props.size}
				onClick={props.chevron ? handleClick : undefined}
			>
				{props.floatedContent ? (
					<span className='right floated' key='floated'>
						{props.floatedContent}
					</span>
				) : null}
				{props.chevron ? (
					<span className='icon chevron-icon'>
						<Icon rotate={chevron === 'up' ? -180 : 0} name='fa-chevron-down' key='chevron' />
					</span>
				) : null}
				{props.icon && !props.chevron ? (
					typeof props.icon === 'string' ? (
						<span className='icon'>
							<Icon name={props.icon} key='icon' />
						</span>
					) : (
						<span className='icon'>
							<Icon {...props.icon} key='icon' />
						</span>
					)
				) : null}
				{props.iconGroup && !props.chevron ? (
					<span className='icon'>
						<Icon.Group {...props.iconGroup} key='icon-group' />
					</span>
				) : null}
				{props.image ? (
					typeof props.image === 'string' ? (
						<span className='image'>
							<Image circular src={props.image} key='image' />
						</span>
					) : (
						<span className='image'>
							<Image {...props.image} key='image' />
						</span>
					)
				) : null}
				{props.checkbox ? <Checkbox {...props.checkbox} label={content} key='checkbox' /> : content}
			</Header>
		);
	};

	const getPopup = () => {
		let help = null,
			icon = (
				<span className='cursor-help valign-middle'>
					<Icon name='fa-question-circle' color='primary' size='small' fitted />
				</span>
			);
		if (typeof props.help === 'string' || React.isValidElement(props.help)) {
			help = <Popup trigger={icon} text={props.help} />;
		} else {
			help = <Popup trigger={icon} {...props.help} />;
		}

		return help;
	};

	const getContent = () => {
		return (
			<>
				<div className='header-wrapper'>
					{props.text ? <span className='text'>{props.text}</span> : null}
					{props.help ? getPopup() : null}
					{props.labels ? (
						<Label.Group
							as='span'
							size='tiny'
							labels={Array.isArray(props.labels) ? props.labels : [props.labels]}
						/>
					) : null}
					{props.upsell ? (
						<span className='icon upsell'>
							<Icon squared fitted name='fa-gem' color='purple' size='small' />
						</span>
					) : null}
				</div>
				{props.subheader ? <Header.Subheader content={props.subheader} /> : null}
				{props.subinfo ? (
					<Header.Subheader
						content={
							Array.isArray(props.subinfo) ? (
								<List horizontal divided items={props.subinfo} />
							) : (
								props.subinfo
							)
						}
						className='sub-info'
					/>
				) : null}
			</>
		);
	};

	// Display reponsively
	let header =
		props.minDevice || props.maxDevice ? (
			<Responsive {...props}>{createHeader(props)}</Responsive>
		) : (
			createHeader(props)
		);

	return header;
});

// Documentation generation support
Component.displayName = 'Header';
Component.defaultProps = DEFAULTS;

export type { Props };
export default Component;
