/**
 * @prettier
 * @flow
 */

import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import Validate from 'liana-ui/definitions/component/Validate';
import { Card } from 'semantic-ui-react';
import {
	Popup,
	Image,
	Button,
	ContextMenu,
	Checkbox,
	Label,
	List,
	Grid,
	NavLink,
	Transition,
	Visibility
} from 'liana-ui/components/';

import type { IntlComponent } from 'react-intl';
import type { Props as ImageProps } from 'liana-ui/components/image/Image';
import type { Props as ButtonProps } from 'liana-ui/components/button/Button';
import type { Props as ButtonGroupProps } from 'liana-ui/components/button/ButtonGroup';
import type { Props as ContextMenuProps } from 'liana-ui/components/menu/ContextMenu';
import type { Props as LabelProps } from 'liana-ui/components/label/Label';
import type { Props as LimitLabelProps } from 'liana-ui/components/label/LimitLabel';
import type { Props as PopupProps } from 'liana-ui/components/popup/Popup';

// prettier-ignore
type Props = {
	/** A selectable or identifiable card must have an id */
	id?: number | string,
	/**
	 	A card can have and image. 
	 	PROPS[ImageProps=/components/texts/image]
	*/
	image?: string | ImageProps,
	/** 
	 	A card can have a header. 
	 	PROPS[IntlComponent=/language/localisation]
	*/
	header: string | IntlComponent | React.Node,
	/** Only the card header can be a link. Opens absolute links in new browser tab and internal links via router. */
	headerLink?: string,
	/** The whole card can be a link. Opens absolute links in new browser tab and internal links via router. */
	itemLink?: string,
	/** 
	 	A card can have a description. 
	 	PROPS[IntlComponent=/language/localisation]
	*/
	description?: string | IntlComponent | React.Node,
	/** A card can sub info text or content. */
	subinfo?: string | React.Node | Array<React.Node>,
	/**
		A header can have labels and/or limit label.
		PROPS[LabelProps=/components/labels/labels/label/, LimitLabelProps=/components/labels/limit-label/]
	*/
	labels?:
		LabelProps
		| Array<LabelProps>
		| LimitLabelProps,
	/** 
	 	A card can have action buttons or context menus. Max 5 recommended.
		PROPS[ButtonProps=/components/buttons/button/, ContextMenuProps=/components/menus/context-menu] 
	*/
	actions?: Array<ButtonProps | ContextMenuProps>,
	/** 
		A card can have single button. 
		PROPS[ButtonProps=/components/buttons/button/]
	*/
	button?: ButtonProps,
	/** 
		A card can have a button group. 
		PROPS[ButtonGroupProps=/components/buttons/button-group/]
	*/
	buttonGroup?: ButtonGroupProps,
	/** A card can have different layouts. */
	layout?: 'small' | 'big',
	/** 
	  	A card can limit header to maximum amount of lines with ellipsis. 
	 	VALUES[1 - 10]
	*/
	limitHeader?: number,
	/** 
	    A card can limit description to maximum amount of lines with ellipsis. 
		VALUES[1 - 10]			
	*/
	limitDescription?: number,
	/** A card can be in a selected state. */
	selected?: boolean,
	/** A card can be in a viewed state. */
	viewed?: boolean,
	/** A card can animate (scale away) when deleted. */
	deleted: boolean,
	/** A card can take the width of its container. */
	fluid?: boolean,
	/** A card can indicate it contains multiple stacked segments. */
	stacked?: boolean,
	/**
		Popup text or, react-intl coomponent or object of properties for Popup component.
		PROPS[IntlComponent=/language/localisation/, PopupProps=/components/modals/popup/]
	*/
	popup?: string | IntlComponent | PopupProps,
	/** Function called on card select. */
	onSelect?: (
		event: SyntheticEvent<>,
		data: {
			selected: boolean
		}
	) => void,
	/** Function called on card click. */
	onClick?: (
		event: SyntheticEvent<>
	) => void,
	/** Element's top edge has passed bottom of screen. */
	onTopVisible?: (id: number | string) => void,
	/** Callback on when delete animation ends. */
	onAfterDeleteItems?: () => void
};

const DEFAULTS = {
	viewed: false,
	deleted: false,
	stacked: false
};

/** COMPONENT BASED ON: https://react.semantic-ui.com/views/card/ */
const Component: React.AbstractComponent<Props, mixed> = React.memo<Props>((props: Props) => {
	const navigate = useNavigate();

	// Get link type
	let headerLinkType = Validate.linkType(props.headerLink);
	let itemLinkType = Validate.linkType(props.itemLink);

	const handleContainerClick = (event: SyntheticEvent<HTMLButtonElement>) => {
		// Trigger onClick callback funtion
		if (typeof props.onClick === 'function') {
			props.onClick(event);
		}

		// Trigger internal link
		if (itemLinkType && (itemLinkType === 'internal' || itemLinkType === 'anchor')) {
			event.preventDefault();
			if (itemLinkType === 'internal') {
				navigate(props.itemLink);
			}
			if (itemLinkType === 'anchor') {
				Safely.scroll(props.itemLink, () => {
					navigate(`${window.location.pathname}${props.itemLink}`);
				});
			}
		}
	};

	const getActions = () => {
		let actions = [];
		if (Array.isArray(props.actions)) {
			for (let i = 0; i < props.actions.length; i++) {
				if (props.actions[i].button) {
					if (
						typeof props.actions[i].button?.popup === 'string' ||
						React.isValidElement(props.actions[i]).button?.popup
					) {
						props.actions[i].button.popup = {
							text: props.actions[i].button.popup,
							delay: 500
						};
					}
					actions.push(
						<Grid.Column collapsing key={`button-column-${i}`}>
							<Button {...props.actions[i].button} basic fitted size='tiny' noWrap />
						</Grid.Column>
					);
				} else if (typeof props.actions[i].contextMenu) {
					actions.push(
						<Grid.Column collapsing key='context-menu-column'>
							<ContextMenu {...props.actions[i].contextMenu} fitted basic size='tiny' direction='left' />
						</Grid.Column>
					);
				}
			}
		}
		return actions.length > 0 ? (
			<Card.Content extra className='item-actions'>
				<Grid columns='equal' compact='very' textAlign='center' verticalAlign='middle' singleRow>
					{actions}
				</Grid>
			</Card.Content>
		) : null;
	};

	// Assign classes
	let classes = classNames({
		'layout-big': !props.layout || props.layout === 'big',
		'layout-small': props.layout === 'small',
		'has-image': props.image,
		selected: props.selected,
		viewed: props.viewed,
		segment: props.stacked,
		stacked: props.stacked,
		fluid: props.fluid
	});

	// Assign image classes
	let imageClasses = classNames('ui image image-wrapper', {
		'image-ratio': props?.image?.ratio,
		'mini right floated': props.layout === 'small'
	});

	// Count fluid placeholder ratio
	let styles = {};
	if (props?.image?.ratio && typeof props.image.ratio === 'string' && props.image.ratio.indexOf('/') > -1) {
		let ratio = props.image.ratio;
		styles = {
			paddingTop: (ratio.split('/')[1] / ratio.split('/')[0]) * 100 + '%'
		};
	}

	// Delete transition
	let visible = props.deleted ? !props.deleted : true,
		transitionOnMount = props.deleted ? false : props.added ? true : null,
		animation = props.deleted ? 'fly left' : props.added ? 'glow' : null,
		duration = props.deleted ? 750 : props.added ? 1000 : 0,
		onHide = props.deleted ? props.onAfterDeleteItems : null,
		style = props.deleted ? { background: 'yellow' } : undefined;

	// Define Card
	let card = (
		<Transition
			visible={visible}
			transitionOnMount={transitionOnMount}
			unmountOnHide={true}
			animation={animation}
			duration={duration}
			onHide={onHide}
			reactKey={`animation-${props.id}`}
		>
			<Card
				key={`card-${props.id}`}
				style={style}
				as={typeof props.onClick === 'function' ? 'a' : undefined}
				className={classes}
				href={props.itemLink}
				target={itemLinkType === 'external' ? '_blank' : undefined}
				rel={itemLinkType === 'external' ? 'noopener noreferrer' : undefined}
				onClick={typeof props.onClick === 'function' ? handleContainerClick : undefined}
			>
				{(!props.layout || props.layout === 'big') && props.image ? (
					<div className={imageClasses} style={styles}>
						{typeof props.image === 'string' ? (
							<Image reactKey={`image-${props.id}`} src={props.image} />
						) : (
							<Image reactKey={`image-${props.id}`} {...props.image} />
						)}
					</div>
				) : null}
				{typeof props.onSelect === 'function' || props.new ? (
					<div className='state-container'>
						{typeof props.onSelect === 'function' ? (
							<span className='select-checkbox'>
								<Checkbox
									checked={props.selected}
									name='item'
									value={props.id}
									onChange={props.onSelect}
									fitted
								/>
							</span>
						) : null}
						{props.new ? (
							<span className='new-label'>
								<Label
									text={<FormattedMessage id='component.label.new' />}
									size='tiny'
									notification
									fitted
								/>
							</span>
						) : null}
					</div>
				) : null}
				<Card.Content>
					{props.layout === 'small' && props.image ? (
						<div className='ui image mini right floated'>
							<div className={imageClasses} style={styles}>
								{typeof props.image === 'string' ? (
									<Image
										key={`image-${props.id}`}
										rounded
										src={props.image}
										size='mini'
										floated='right'
									/>
								) : (
									<Image key={`image-${props.id}`} {...props.image} size='mini' floated='right' />
								)}
							</div>
						</div>
					) : null}
					<Card.Header
						as='h3'
						className={`item-header text-hyphenate${
							props.limitHeader ? ` text-lines-${props.limitHeader}` : ''
						}`}
					>
						{props.headerLink ? (
							<NavLink
								to={props.headerLink}
								target={headerLinkType === 'external' ? '_blank' : undefined}
								rel={headerLinkType === 'external' ? 'noopener noreferrer' : undefined}
							>
								{props.header}
							</NavLink>
						) : (
							props.header
						)}
					</Card.Header>
					{props.subinfo ? (
						<Card.Meta>
							<List horizontal divided size='small' items={props.subinfo} />
						</Card.Meta>
					) : null}
					<Card.Description>
						<p className={props.limitDescription ? `text-lines-${props.limitDescription}` : ''}>
							{props.description}
						</p>
						{props.labels ? (
							<Label.Group
								as='span'
								size='tiny'
								labels={Array.isArray(props.labels) ? props.labels : [props.labels]}
							/>
						) : null}
					</Card.Description>
				</Card.Content>
				{getActions()}
				{props.button ? (
					<Card.Content extra>
						<Button {...props.button} fluid size='small' />
					</Card.Content>
				) : null}
				{props.buttonGroup ? (
					<Card.Content extra>
						<Button.Group {...props.buttonGroup} fluid size='small' />
					</Card.Content>
				) : null}
			</Card>
		</Transition>
	);

	if (typeof props.onTopVisible === 'function') {
		card = <Visibility onTopVisible={() => props.onTopVisible(props.id)}>{card}</Visibility>;
	}

	return <>{Popup.attach(props.popup, card)}</>;
});

// Documentation generation support
Component.displayName = 'Card';
Component.defaultProps = DEFAULTS;

export type { Props };
export default Component;
