/**
 * @prettier
 * @flow
 */

import { Link } from 'react-router-dom';
import { Gainsight } from 'liana-ui/definitions';
import Validate from 'liana-ui/definitions/component/Validate';
import { FormattedMessage } from 'react-intl';
import { Dropdown } from 'semantic-ui-react';
import { Header, List, Image, Popup, Segment, Responsive } from 'liana-ui/components/';
import type { Props as PopupType } from 'liana-ui/components/popup/Popup';

// prettier-ignore
type Props = {
	/** If is open */
	open: boolean,
	/** User menu data. User object: DATA[json/user/user.json] */
	user: {
		id: string,
		selectedOrganization: string,
		selectedSolution: string,
		name: string,
		email: string,
		image: string,
		organizations: Array<{ name: string, ... }>,
		links: {
			userSettings: string,
			organizationSettings: string,
			logout: string,
			privacyPolicy: string,
			termsOfService: string
		},
		...
	},
	/** Position, with and heigh of dropdown menu */
	style: { [key: string]: string },
	/** Object of properties for Popup */
	popup?: PopupType,
	/** Called on organization change. Returns organization id. */
	onOrganizationChange: (string) => string,
	/** Called on user logout. Returns logout url. */
	onLogout?: (string) => string,
	/** Called on menu open. */
	onOpen?: (
		event: SyntheticEvent<>,
		data: { id: string }
	) => void,
	/** Called on menu close. */
	onClose?: (
		event: SyntheticEvent<>,
		data: { id: string }
	) => void
};

const DEFAULTS = {
	open: false
};

const ID = 'user';

/** COMPONENT BASED ON: https://react.semantic-ui.com/modules/dropdown/ and https://react.semantic-ui.com/collections/menu/ */
const Component: React.AbstractComponent<Props, mixed> = React.memo<Props>((props: Props) => {
	// Render nothing if empty
	if (!props.user) {
		return null;
	}

	const isMobile =
		document.querySelector('html').classList.contains('mobile') ||
		document.querySelector('html').classList.contains('tablet');

	const getUserName = () => {
		return typeof props.user.name !== 'undefined' && props.user.name !== '' ? props.user.name : props.user.email;
	};

	const getOrganizationID = () => {
		let organizationID = props.user.selectedOrganization;
		// If organization not selected pick first
		if (!organizationID && props.user.organizations && props.user.organizations.length > 0) {
			organizationID = props.user.organizations[0].id;
		}
		return organizationID;
	};

	const getOrganizationName = () => {
		let organizationName = '';
		let organizationID = getOrganizationID();

		if (props.user.organizations && props.user.organizations.length > 0) {
			props.user.organizations.map((option) => {
				if (option.id === organizationID) {
					organizationName = option.name;
				}
			});
		}

		return organizationName;
	};

	const getUserLinks = () => {
		// Render nothing if empty
		if (!props.user.links) {
			return null;
		}

		let userLinkType = undefined,
			organizationLinkType = undefined;

		// Get link types
		if (props.user.links.userSettings) {
			userLinkType = Validate.linkType(props.user.links.userSettings);
		}
		if (props.user.links.organizationSettings) {
			organizationLinkType = Validate.linkType(props.user.links.organizationSettings);
		}

		return (
			<>
				{props.user.links.userSettings ? (
					<Dropdown.Item
						as={userLinkType === 'internal' ? Link : 'a'}
						href={userLinkType === 'internal' ? undefined : props.user.links.userSettings}
						to={userLinkType === 'internal' ? props.user.links.userSettings : false}
						icon='fa-user'
						className='text-hyphenate'
						text={<FormattedMessage id='component.user-menu.userAccount' />}
					/>
				) : null}
				{props.user.links.organizationSettings ? (
					<Dropdown.Item
						as={organizationLinkType === 'internal' ? Link : 'a'}
						href={organizationLinkType === 'internal' ? undefined : props.user.links.organizationSettings}
						to={organizationLinkType === 'internal' ? props.user.links.organizationSettings : false}
						icon='fa-building'
						className='text-hyphenate'
						text={<FormattedMessage id='component.user-menu.organizationAccount' />}
					/>
				) : null}
				{props.user.links.logout ? (
					<Dropdown.Item
						icon='fa-arrow-right-from-bracket'
						className='text-hyphenate'
						text={<FormattedMessage id='component.user-menu.logOut' />}
						onClick={handleLogout}
					/>
				) : null}
			</>
		);
	};

	const getOrganizations = () => {
		// Render nothing if empty
		if (
			!props.user ||
			!props.user.organizations ||
			props.user.organizations.length < 2 ||
			typeof props.onOrganizationChange !== 'function'
		) {
			return null;
		}

		let organizationID = getOrganizationID();

		let menu = [
			<Dropdown.Header content={<FormattedMessage id='component.user-menu.organization' />} key='header' />,
			<Dropdown.Divider key='divider' />
		];

		let previousSection = null;
		props.user.organizations.map((organization, i) => {
			if (i > 0 && organization.section && organization.section !== previousSection) {
				menu.push(<Dropdown.Divider key={`divider${i}`} />);
			}
			menu.push(
				<Dropdown.Item
					key={`item${i}`}
					className='organization text-hyphenate'
					id={organization.id}
					image={<Image src={organization.image} squared avatar='building' size='favicon' />}
					text={organization.name}
					active={organization.id === organizationID}
					onClick={handleChangeOrganization}
				/>
			);
			previousSection = organization.section;
		});

		return menu;
	};

	const getContractLinks = () => {
		// Render nothing if empty
		if (!props.user || !props.user.links || (!props.user.links.privacyPolicy && !props.user.links.termsOfService)) {
			return null;
		}

		return (
			<>
				<Dropdown.Divider key='divider' />
				<Segment basic compressed removeMargins='all'>
					<List
						size='tiny'
						items={[
							props.user.links.privacyPolicy
								? {
										icon: 'external',
										content: <FormattedMessage id='component.user-menu.privacyPolicy' />,
										link: props.user.links.privacyPolicy
								  }
								: null,
							props.user.links.termsOfService
								? {
										icon: 'external',
										content: <FormattedMessage id='component.user-menu.termsOfService' />,
										link: props.user.links.termsOfService
								  }
								: null
						]}
					/>
				</Segment>
			</>
		);
	};

	const handleOpen = (event: ?SyntheticEvent<>, data: any) => {
		if (typeof props.onOpen === 'function') {
			props.onOpen(event, handleCallbackData(data));
		}
	};

	const handleClose = (event: ?SyntheticEvent<>, data: any) => {
		if (typeof props.onClose === 'function') {
			props.onClose(event, handleCallbackData(data));
		}
	};

	const handleChangeOrganization = (event: ?SyntheticEvent<>, data: any) => {
		if (typeof props.onOrganizationChange === 'function') {
			props.onOrganizationChange(data.id);
		}
	};

	const handleLogout = () => {
		Gainsight.reset();
		if (typeof props.onLogout === 'function') {
			props.onLogout(props.user.links.logout);
		}
	};

	// Handle data returned by onClose callback.
	const handleCallbackData = (data: any) => ({
		id: data.id
	});

	let menu = (
		<Dropdown
			id={`${ID}Menu`}
			tabIndex={false}
			closeOnBlur={false}
			open={props.open}
			item
			icon={
				<span>
					<Image
						fitted
						avatar={isMobile && props.open ? 'close' : 'user'}
						src={isMobile && props.open ? undefined : props.user.image}
						alt='Avatar'
						lazyLoad={false}
						maxDevice='tablet'
					/>
				</span>
			}
			text={
				<Header
					text={getUserName()}
					subheader={getOrganizationName()}
					size='small'
					image={{
						avatar: true,
						src: props.user.image,
						alt: 'Avatar',
						lazyLoad: false
					}}
					minDevice='computer'
				/>
			}
			onOpen={handleOpen}
			onClose={handleClose}
		>
			<Dropdown.Menu id={`${ID}Dropdown`} style={props.style}>
				{props.open ? (
					<>
						<Responsive maxDevice='tablet'>
							<div
								className='header'
								onClick={(event) => {
									event.stopPropagation();
								}}
							>
								<Header
									dividing
									text={getUserName()}
									subheader={getOrganizationName()}
									image={{
										avatar: true,
										src: props.user.image,
										circular: true
									}}
								/>
							</div>
						</Responsive>
						{getUserLinks()}
						{getOrganizations()}
						{getContractLinks()}
					</>
				) : null}
			</Dropdown.Menu>
		</Dropdown>
	);

	// Display popup
	menu = !props.open ? (
		<Popup trigger={menu} text={<FormattedMessage id='component.user-menu.name' />} {...props.popup} />
	) : (
		menu
	);

	return menu;
});

// Documentation generation support
Component.displayName = 'UserMenu';
Component.defaultProps = DEFAULTS;

export type { Props };
export default Component;
