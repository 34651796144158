/**
 * @prettier
 * @flow
 */

import classNames from 'classnames';
import { filter, escapeRegExp } from 'lodash';
import { useState, forwardRef } from 'react';
import { FormattedMessage } from 'react-intl';
import ReactTextareaAutocomplete from '@webscopeio/react-textarea-autocomplete';
import { Text, Segment, Popup, Label } from 'liana-ui/components';
import type { IntlComponent } from 'react-intl';
import type { Props as PopupProps } from 'liana-ui/components/popup/Popup';

// prettier-ignore
type Props = {
	/** A textarea must have an input name */
	name: string,
	/** Initial value for input. Use for uncontrolled components only. */
	defaultValue?: string,
	/** Current value for input. Use for controlled components only. */
	value?: string,
	/** An input can have a placeholder text. Use intl.formatMessage() for translated strings. */
	placeholder?: string,
	/** A textarea can have initial rows set. VALUES[1, 2, 3, 4, ...] */
	rows: number,
	/** A textare can limit possible characters to maximum amount. */
	maxLength?: number,
	/** An textarea can be locked to indicate that the field is in use but can not be edited. */
	locked: boolean,
	/** A textarea can be locked. */
	disabled: boolean,
	/** A textarea can be read only. */
	readOnly: boolean,
	/** An textarea can be different size. */
	size?: 'small' | 'large',
	/** A textarea can have autocomplete placeholder tags. */
	autocomplete?: {
		trigger: string,
		options: Array<{
			tag: string,
			description: string,
			header: string,
			disabled?: boolean,
			label?: string
		}>,
		translations?: {
			noResults?: string,
		},
		onSearch: string
	},
	popup?: string | IntlComponent | PopupProps,
	/** Function called on input focus. */
	onFocus?: (
		event: SyntheticEvent<>,
		data: {
			name: string,
			value: string
		}
	) => void,
	/** Function called on input change. */
	onChange?: (
		event: SyntheticEvent<>,
		data: {
			name: string,
			value: string
		}
	) => void
};

// Component default property values
const DEFAULTS = {
	rows: 6,
	locked: false,
	disabled: false,
	readOnly: false,
	autocomplete: { trigger: '$' }
};

// Component render conditions. True if should not render.
const EQUALS = (prevProps, nextProps) =>
	prevProps.value === nextProps.value &&
	prevProps.disabled === nextProps.disabled &&
	prevProps.locked === nextProps.locked;

/** COMPONENT BASED ON: https://react.semantic-ui.com/addons/text-area/ */
const Component: React.AbstractComponent = React.memo(
	forwardRef((props: Props, ref: any) => {
		// Count amount of charachters
		const getLength = (val: string) => {
			if (val) {
				return val.length;
			} else {
				return 0;
			}
		};

		// Internal states
		let [search, setSearch] = useState();
		let [internalValue, setInternalValue] = useState(props.defaultValue);
		let value = props.value === undefined ? internalValue : props.value;

		const filterOptions = (rawOptions, search) => {
			const re = new RegExp(escapeRegExp(search && search.toLowerCase()), 'i');
			const isMatch = (result) => re.test(result.tag) || re.test(result.description);
			let filteredOptions = filter(rawOptions, isMatch);
			return filteredOptions.every((el) => el.header) ? [] : filteredOptions;
		};

		// Limit value to maxLength
		if (getLength(value) > props.maxLength) {
			value = value.substring(0, props.maxLength);
		}

		// Called on input focus.
		const handleFocus = (event: ?SyntheticEvent<>) => {
			if (typeof props.onFocus === 'function') {
				props.onFocus(event, handleCallbackData({ value: event.target.value }));
			}
		};

		// Called on input change.
		const handleChange = (event: ?SyntheticEvent<>, data: any) => {
			// Set current value internally
			setInternalValue(data.value);

			// Trigger onChange callback with formatted data
			if (typeof props.onChange === 'function') {
				props.onChange(event, handleCallbackData(data));
			}

			// Trigger Form onChange on value change
			if (typeof event === 'object' && event.target && typeof event.target.dispatchEvent === 'function') {
				event.target.dispatchEvent(new Event('change', { bubbles: true }));
			}
		};

		// Handle data returned by callbacks.
		const handleCallbackData = (data: any) => {
			return {
				name: props.name,
				value: data.value
			};
		};

		const wrapperClasses = classNames(
			'textarea',
			{
				'has-counter': props.maxLength && !props.disabled && !props.locked
			},
			props.size
		);

		const textareaClasses = classNames({
			disabled: props.disabled,
			locked: props.locked
		});

		const listClasses = classNames('ui vertical menu compact tiny', {
			filtered: search
		});

		if (props.value && props.defaultValue) {
			delete props.defaultValue; // Having both will render defaultValue as textContent
		}

		let textarea = (
			<div className={wrapperClasses} data-default={props.defaultValue}>
				<ReactTextareaAutocomplete
					name={props.name}
					className={textareaClasses}
					listClassName={listClasses}
					placeholder={props.placeholder}
					value={value}
					rows={props.rows}
					maxLength={props.maxLength}
					disabled={props.disabled}
					readOnly={props.readOnly || props.locked}
					onFocus={handleFocus}
					onChange={(event) => handleChange(event, { value: event.target.value })}
					minChar={0}
					movePopupAsYouType
					loadingComponent={() => <span>Loading</span>}
					trigger={{
						[props.autocomplete.trigger || '$']: {
							dataProvider: (token) => {
								const filteredOptions = filterOptions(props.autocomplete.options, token);
								const noOptions = [{ no_results: true }];

								setSearch(token);
								if (typeof props.autocomplete.onSearch === 'function') {
									props.autocomplete.onSearch(token);
								}

								return filteredOptions.length ? filteredOptions : noOptions;
							},
							component: ({ entity: { tag, description, header, disabled, label, no_results } }) => {
								if (no_results) {
									return (
										<a className='item no-results cursor-default'>
											<Text as='div' color='grey'>
												{props?.autocomplete?.translations?.noResults || (
													<FormattedMessage id='component.search-input.noResultsFound' />
												)}
											</Text>
										</a>
									);
								}

								return header ? (
									<div className='item header-wrapper text-uppercase'>
										<div className='header'>{header}</div>
									</div>
								) : (
									<a
										className={classNames('item', {
											disabled
										})}
									>
										<Text as='div'>
											{tag} {label ? <Label text={label} /> : null}
										</Text>
										{description ? (
											<Text as='div' color='grey' size='small'>
												{description}
											</Text>
										) : null}
									</a>
								);
							},
							output: (item) => {
								return !item.disabled && !item.no_results ? item.tag || item.header : '';
							}
						}
					}}
				/>
				{props.maxLength && !props.disabled && !props.locked ? (
					<Segment attached='bottom' size='tiny' raised compressed='very'>
						&nbsp;&nbsp;
						<FormattedMessage id='component.textarea.characters' />:{' '}
						<Text bolder color={getLength(value) >= props.maxLength ? 'red' : undefined}>
							{getLength(value) + '/' + props.maxLength.toString()}
						</Text>
					</Segment>
				) : null}
			</div>
		);

		// Display popup
		if (props.popup) {
			textarea =
				typeof props.popup === 'string' || React.isValidElement(props.popup) ? (
					<Popup trigger={textarea} text={props.popup} />
				) : (
					<Popup trigger={textarea} {...props.popup} />
				);
		}

		return textarea;
	}),
	EQUALS
);

// Documentation generation support
Component.displayName = 'Textarea';
Component.defaultProps = DEFAULTS;

export type { Props };
export default Component;
