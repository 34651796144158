/**
 * @prettier
 */
import { Auth0Provider } from '@auth0/auth0-react';
import React from 'react';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { Fetch } from 'liana-ui/definitions';
import { Message } from 'liana-ui';

export const Auth0ProviderWithNavigate = ({ children }) => {
	const [configLoadingDone, setConfigLoadingDone] = useState(false);
	const [config, setConfig] = useState(null);
	const history = createBrowserHistory();
	const [searchParams, setSearchParams] = useSearchParams();

	const redirectUri = window.location.origin;

	const onRedirectCallback = (appState) => {
		const returnTo = appState && appState.returnTo ? appState.returnTo : window.location.pathname;
		history.push(returnTo);
	};

	if (!configLoadingDone) {
		let base = process.env.baseUrl || '/';
		Fetch.get(`${base}json/config.json`).then((config) => {
			setConfig(config);
			setConfigLoadingDone(true);
		});
	}

	return configLoadingDone ? (
		!config.AUTH0_DOMAIN || !config.AUTH0_CLIENT_ID || !config.AUTH0_AUDIENCE ? (
			<div className='main-column'>
				<Message
					error
					layout='big'
					header={'An error occurred - Contact support'}
					content={'Configs are invalid'}
				/>
			</div>
		) : (
			<Auth0Provider
				domain={config.AUTH0_DOMAIN}
				clientId={config.AUTH0_CLIENT_ID}
				authorizationParams={{
					redirect_uri: redirectUri,
					audience: config.AUTH0_AUDIENCE,
					aid: searchParams.get('aid') || undefined,
					returnTo: window.location.origin,
					applicationType:'adm'
				}}
				onRedirectCallback={onRedirectCallback}
			>
				{children}
			</Auth0Provider>
		)
	) : (
		<div className='ui active loader' />
	);
};
