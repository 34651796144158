/* global aptrinsic */

let key = null;

// Note: user.userHash is required for Gainsight to work properly
const init = (config, user, customAttributes, options) => {
	if (typeof aptrinsic !== 'function' && config.GAINSIGHT_KEY && user) {
		key = config.GAINSIGHT_KEY;
		const script = document.createElement('script');
		script.innerHTML =
			'(function(n,t,a,e,co){var i="aptrinsic";n[i]=n[i]||function(){(n[i].q=n[i].q||[]).push(arguments)},n[i].p=e;n[i].c=co;var r=t.createElement("script");r.async=!0,r.src=a+"?a="+e;var c=t.getElementsByTagName("script")[0];c.parentNode.insertBefore(r,c)})(window,document,"https://web-sdk-eu.aptrinsic.com/api/aptrinsic.js","' +
			config.GAINSIGHT_KEY +
			'");';
		document.head.appendChild(script);

		identify(user, customAttributes);

		if (options) {
			if (Array.isArray(options.searchLabels)) {
				aptrinsic('addListener', 'kcTriggered', () => {
					setBot({ labels: options.searchLabels }); // Filters search results by passed labels
				})
			}
		}
	} else {
		console.error('Please pass config with config.GAINSIGHT_KEY and user object to Gainsight.init(config, user); function.');
	}
};

const update = (user, customAttributes) => {
	if (user) {
		identify(user, customAttributes);
	} else {
		console.error('Please pass user object to Gainsight.update(user); function.');
	}
};

const feedback = (data, categories)=>{
	if (categories) {
		const feedbackObject = {
			category: categories.find((c) => c.value === data.category).id,
			subject: `${categories.find((c) => c.value === data.category).text}`,
			description: data.description
		};

		const required = ['category', 'subject', 'description'];
		for (let x of required) {
			if (! feedbackObject[x]) {
				console.error(`Missing required feedback parameter: ${x}`);
				return;
			}
		}
		if (typeof aptrinsic === 'function') {
			aptrinsic('send', 'feedback', {
				...feedbackObject
			});
		}
	}
	return;
}

// Note: user.userHash is required for Gainsight to work properly
const identify = (user, customAttributes) => {
	const gainsightUser = {
		id: user.id,
		fullName: user.name,
		email: user.email,
		phone: user.phoneNumber?.international,
		timeZone: user.timezone.name,
		countryCode: user.country,
		language: user.language,
		userHash: user.userHash,
		...customAttributes?.user
	};

	const gainsightCustomer = {
		id: user.selectedOrganization,
		name: getOrganizationName(user),
		...customAttributes?.account
	};
	if (typeof aptrinsic === 'function') {
		aptrinsic('identify', gainsightUser, gainsightCustomer);

		document.querySelector('script[src^="https://web-sdk-eu.aptrinsic.com/api/aptrinsic.js').addEventListener('load', function(){
			setTimeout(() => {
				if (!document.getElementById("apt-widget-controller") || document.querySelector('html').classList.contains('tablet') || document.querySelector('html').classList.contains('mobile')) {
					document.head.insertAdjacentHTML("beforeend", `
					<style id='apt-widget-container'>
						.apt-widget-container {
							left: auto !important;
							top: auto !important;
							right: 1.14286rem !important;
							bottom: 1.14286rem !important;
							transform: translate(0px, 0px) !important;
						}
					</style>
					`);
				} else {
					document.body.setAttribute('data-button', "true");
				}
			}, 1000);
		});
	}
};

const openBot = () => {
	if (typeof aptrinsic === 'function') {
		aptrinsic('kcb', 'open', {});
		if (document.getElementById('apt-widget-container') != null && (document.querySelector('html').classList.contains('tablet') || document.querySelector('html').classList.contains('mobile'))) {
			document.getElementById('apt-widget-container').remove();
			document.body.setAttribute('data-button-open', "true");
		}
	} else {
		error();
	}
};


const closeBot = () => {
	if (typeof aptrinsic === 'function') {
		aptrinsic('kcb', 'close', {});
		document.body.setAttribute('data-button-open', "false");
	} else {
		error();
	}
};

const reset = () => {
	if (typeof aptrinsic === 'function') {
		aptrinsic('reset');
	}
};

const getOrganizationName = (user) => {
	let organizationName = '';
	if (user.organizations && user.organizations.length > 0) {
		user.organizations.map((option) => {
			if (option.id === user.selectedOrganization) {
				organizationName = option.name;
			}
		});
	}
	return organizationName;
};

const getKey = () => {
	return key; 
};

const error = () => {
	console.error('Please init Gainsight by adding this to App.js: Gainsight.init(config, user);');
}

const setBot = (value, key = 'search') => aptrinsic('bot', key, value);

export default {
	init,
	identify,
	update,
	feedback,
	getKey,
	openBot,
	closeBot,
	reset,
	setBot
};
