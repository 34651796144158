/**
 * @prettier
 * @flow
 */

import classNames from 'classnames';
import { useRef, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Dropdown } from 'semantic-ui-react';
import { Icon, Image, Flag, Header, Button, Popup } from 'liana-ui/components/';
import type { IntlComponent } from 'react-intl';
import type { Props as IconProps } from 'liana-ui/components/icon/Icon';
import type { Props as ImageProps } from 'liana-ui/components/image/Image';
import type { Props as PopupProps } from 'liana-ui/components/popup/Popup';

// prettier-ignore
type Props = {
	/** A dropdown must have an input name */
	name: string,
	/**
		A dropdown can have options.
		PROPS[IconProps=/components/labels/icons/icon/, ImageProps=/components/texts/image/, PopupProps=/components/modals/popup/, IntlComponent=/language/localisation/]
		DATA[json/dropdown/dropdown.json]
	*/
	options?: Array<{
		text: string | IntlComponent,
		content: React.Node,
		value: string,
		description: string,
		icon: string | IconProps,
		image: string | ImageProps,
		label: string | IntlComponent,
		disabled: boolean,
		popup: string | IntlComponent | PopupProps
	}>,
	/** Initial value or value array if multiple. Use for uncontrolled components only. */
	defaultValue?: string | Array<string>,
	/** Current value or value array if multiple. Use for controlled components only. */
	value?: string | Array<string>,
	/**
		An dropdown can have a placeholder text.
		PROPS[IntlComponent=/localization/]
	*/
	placeholder?: string | IntlComponent,
	/** A dropdown can allow adding new options. */
	allowAdditions?: boolean,
	/**
		A dropdown with allowAdditons enabled can display a custom text when adding new item.
		PROPS[IntlComponent=/localization/]
	*/
	additionText?: string | IntlComponent,
	/**
		A dropdown can display a custom message when there are no results.
		PROPS[IntlComponent=/localization/]
	*/
	noResultsMessage?: string | IntlComponent,
	/** A dropdown can have an icon. */
	icon?: string,
	/** A dropdown can allow multiple selections. */
	multiple?: boolean,
	/** A dropdown can be searchable. A dropdown also allows you to provide your own search function. */
	search?: boolean,
	/** A dropdown can delay onSearchChangeDelay callback. */
	delay: number,
	/** A dropdown can have minimum characters required to query and show remote results. Set at least 1 for remote search dropdowns. */
	minCharacters?: number,
	/** A dropdown can be loading when updating options. */
	loading?: boolean,
	/** A dropdown can be clearable. */
	clearable?: boolean,
	/** A dropdown menu can be scrollable */
	scrolling?: boolean,
	/** A dropdown can take the full width of its parent. */
	fluid?: boolean,
	/** A dropdown can break very long options in tight spaces into multiple lines. */
	breakWord?: boolean,
	/** An dropdown can be locked to indicate that the field is in use but can not be edited. */
	locked?: boolean,
	/** A dropdown can be  disabled. */
	disabled?: boolean,
	/* Whether or not the menu should open when the dropdown is focused. */
	openOnFocus?: boolean,
	/** A dropdown can be different size. */
	size?: 'small' | 'large',
	/* A dropdown can have additional classes. Use for very special features only! */
	className?: string,
	/**
		Popup text or, react-intl coomponent or object of properties for Popup component.
		PROPS[IntlComponent=/language/localisation/, PopupProps=/components/modals/popup/]
	*/
	popup?: string | IntlComponent | PopupProps,
	/** Function called on add item. */
	onAddItem?: (
		event: SyntheticEvent<>,
		data: { value: string }
	) => void,
	/** Function called on dropdown click. */
	onClick?: (
		event: SyntheticEvent<>,
		data: {
			name: string,
			value: string,
			minCharacters: number,
			multiple: boolean,
			options: Array<string>,
			selectedOptions: Array<string>
		}
	) => void,
	/** Function called on dropdown focus. */
	onFocus?: (
		event: SyntheticEvent<>,
		data: {
			name: string,
			value: string,
			minCharacters: number,
			multiple: boolean,
			options: Array<string>,
			selectedOptions: Array<string>
		}
	) => void,
	/** Function called on bropdown blur. */
	onBlur?: (
		event: SyntheticEvent<>,
		data: {
			name: string,
			value: string,
			minCharacters: number,
			multiple: boolean,
			options: Array<string>,
			selectedOptions: Array<string>
		}
	) => void,
	/** Function called on input change. */
	onChange?: (
		event: SyntheticEvent<>,
		data: {
			name: string,
			value: string,
			minCharacters: number,
			multiple: boolean,
			options: Array<string>,
			selectedOptions: Array<string>
		}
	) => void,
	/** Function called on search input change. */
	onSearchChange?: (
		event: SyntheticEvent<>,
		data: {
			name: string,
			value: string,
			searchQuery: string,
			minCharacters: number,
			multiple: boolean,
			options: Array<string>,
			selectedOptions: Array<string>
		}
	) => void,
	/** Function called on search input change after delay. */
	onSearchChangeDelay?: (
		event: SyntheticEvent<>,
		data: {
			name: string,
			value: string,
			searchQuery: string,
			minCharacters: number,
			multiple: boolean,
			options: Array<string>,
			selectedOptions: Array<string>
		}
	) => void
};

// Component default property values
const DEFAULTS = {
	selection: true,
	icon: 'chevron down',
	multiple: false,
	search: false,
	delay: 400,
	loading: false,
	openOnFocus: true,
	clearable: false,
	scrolling: false,
	locked: false,
	disabled: false,
	fluid: true,
	breakWord: false,
	minCharacters: 1,
	additionText: <FormattedMessage id='component.dropdown.add' />,
	noResultsMessage: <FormattedMessage id='component.search-input.noResults' />
};

/** COMPONENT BASED ON: https://react.semantic-ui.com/modules/dropdown/ */
const Component: React.AbstractComponent<Props, mixed> = React.memo<Props>((props: Props) => {
	// Variables and refs
	const intl = useIntl();
	const dropdownWrapperRef = useRef();
	const inputRef = useRef(null);
	let timeoutRef = useRef();

	// Internal states
	const [searchQuery, setSearchQuery] = useState('');
	const [internalValue, setInternalValue] = useState(props.defaultValue);
	const value = props.value === undefined ? internalValue : props.value;

	// Prevent Chrome from using autocomplete on dropdowns: https://stackoverflow.com/questions/15738259/disabling-chrome-autofill
	useEffect(() => {
		if (
			dropdownWrapperRef &&
			dropdownWrapperRef.current &&
			dropdownWrapperRef.current.querySelector('input.search')
		) {
			dropdownWrapperRef.current.querySelector('input.search').setAttribute('autocomplete', 'off,chrome-off');
		}
	}, []);

	// Reset search and cancel delay.
	const resetSearch = () => {
		setSearchQuery('');
		if (timeoutRef.current) {
			clearTimeout(timeoutRef.current);
		}
	};

	// Called on dropdown click.
	const handleClick = (event: ?SyntheticEvent<>) => {
		if (typeof props.onClick === 'function') {
			props.onClick(event, handleCallbackData({ value: value, searchQuery: event.target.value }));
		}
	};

	// Called on dropdown focus.
	const handleFocus = (event: ?SyntheticEvent<>) => {
		resetSearch();
		if (typeof props.onFocus === 'function') {
			props.onFocus(event, handleCallbackData({ value: value, searchQuery: event.target.value }));
		}
	};

	// Called on dropdown focus.
	const handleBlur = (event: ?SyntheticEvent<>, data) => {
		resetSearch();
		if (typeof props.onBlur === 'function') {
			props.onBlur(event, handleCallbackData({ ...data, value: value, searchQuery: event.target.value }));
		}
	};

	// Called on input change
	const handleChange = (event, data) => {
		// Set current value internally
		setInternalValue(data.value);

		// Trigger onChange callback with formatted data
		if (typeof props.onChange === 'function') {
			props.onChange(event, handleCallbackData(data));
		}

		// Trigger Form onChange on value change
		if (inputRef && inputRef.current) {
			inputRef.current.dispatchEvent(new Event('input', { bubbles: true }));
			inputRef.current.dispatchEvent(new Event('change', { bubbles: true }));
		}
	};

	// Called hidden input on change (support for uncontrolled form clear/reset)
	const handleInputChange = (event) => {
		if (event.target.value) {
			handleChange(event, { value: event.target.value, options: props.options });
		} else {
			handleClear(event);
		}
	};

	const handleSearchChange = (event, data) => {
		// Search Dropdown functionality
		if (props.search) {
			setSearchQuery(data.searchQuery);

			if (typeof props.onSearchChange === 'function') {
				props.onSearchChange(
					event,
					handleCallbackData({
						value: data.value,
						searchQuery: data.searchQuery
					})
				);
			}
			// Search Dropdown delay functionality
			if (typeof props.onSearchChangeDelay === 'function') {
				if (timeoutRef.current) {
					clearTimeout(timeoutRef.current);
				}
				timeoutRef.current = setTimeout(() => {
					props.onSearchChangeDelay(
						event,
						handleCallbackData({
							value: data.value,
							searchQuery: data.searchQuery
						})
					);
				}, props.delay);
			}
		}
	};

	// Called on add
	const handleAddItem = (event, data) => {
		resetSearch();
		if (typeof props.onAddItem === 'function') {
			props.onAddItem(event, {
				value: data.value,
				options: data.options
			});
		}
	};

	const handleClear = (event) => {
		handleChange(event, { value: props.multiple ? [] : '', options: props.options });
	};

	// Get selected options for callbacks.
	const getSelectedOptions = (data: any) => {
		if (props.options && data.value) {
			if (!data.multiple && data.value !== '') {
				return props.options.filter((item) => item.value === data.value)[0];
			} else if (data.multiple && data.value.length > 0) {
				let selectedOptions = [];
				if (Array.isArray(data.value)) {
					data.value.forEach((val) => {
						let found = props.options.filter((item) => item.value === val)[0];
						if (found) {
							selectedOptions.push(found);
						}
					});
				}
				return selectedOptions;
			}
		}
		return undefined;
	};

	// Handle data returned by callbacks.
	const handleCallbackData = (data: any) => {
		return {
			name: props.name,
			value: data.value,
			searchQuery: data.searchQuery,
			minCharacters: props.minCharacters,
			multiple: props.multiple,
			options: props.options,
			selectedOptions: getSelectedOptions({ ...data, multiple: props.multiple })
		};
	};

	// Format option layout
	const formatOptions = (options: any) => {
		let formattedOptions = [];
		if (options && options.length > 0) {
			options.forEach((option, i) => {
				if (option.divider) {
					formattedOptions.push({
						...options[i],
						className: 'divider',
						key: `option-${i}`
					});
				} else if (option.description && !option.content) {
					formattedOptions.push({
						...options[i],
						content: <Header size='tiny' text={option.text} subheader={option.description} />,
						icon: option.icon ? getIcon(option.icon) : undefined,
						description: undefined,
						className: 'has-description',
						key: `option-${i}`
					});
				} else {
					const content = option.popup ? (
						typeof option.popup === 'string' || React.isValidElement(option.popup) ? (
							<Popup trigger={<span>{option.text}</span>} text={option.popup} />
						) : (
							<Popup trigger={<span>{option.text}</span>} {...option.popup} />
						)
					) : null;

					formattedOptions.push({
						content: content,
						...options[i],
						icon: option.icon ? getIcon(option.icon) : undefined,
						key: `option-${i}`
					});
				}
			});
			return formattedOptions;
		}
	};

	// Format option layout
	const getIcon = (icon) => {
		if (typeof icon === 'object') {
			return <Icon {...icon} />;
		} else if (typeof icon === 'string') {
			return <Icon name={icon} />;
		}
	};

	// Format placeholder (needs to be string for SUI-R dropdown)
	const formatPlaceholder = (placeholder: any) => {
		let string = '';
		if (typeof placeholder === 'string') {
			string = placeholder;
		} else if (React.isValidElement(placeholder) && placeholder.props?.id) {
			string = intl.formatMessage({ id: placeholder.props.id });
		}
		return string;
	};

	// Render label
	const renderLabel = (item) => {
		const { flag, image, icon, text, popup } = item;

		// TODO: remove this in v3
		// This maintains compatibility with Shorthand API in v1 as this might be called in "Label.create()"
		if (typeof text === 'function') {
			return text;
		}

		const wrappedContent = (
			<span>
				{typeof image === 'object' ? (
					<Image {...image} />
				) : typeof image === 'string' ? (
					<Image src={image} />
				) : null}
				{React.isValidElement(icon) ? (
					icon
				) : typeof icon === 'object' ? (
					<Icon {...icon} />
				) : typeof icon === 'string' ? (
					<Icon name={icon} />
				) : null}
				{flag ? <Flag name={flag} /> : null}
				{text}
			</span>
		);

		return {
			content: popup ? (
				typeof popup === 'string' || React.isValidElement(popup) ? (
					<Popup trigger={wrappedContent} text={popup} />
				) : (
					<Popup trigger={wrappedContent} {...popup} />
				)
			) : (
				wrappedContent
			)
		};
	};

	// If loading
	let loading =
		props.loading && !props.disabled && !props.locked && searchQuery && searchQuery.length >= props.minCharacters;

	// If has value
	let hasValue = (!props.multiple && value) || (props.multiple && value && value.length > 0);

	// Assign classes
	let wrapperClasses = classNames(
		{
			'dropdown-wrapper': props.selection,
			fluid: props.fluid,
			'break-word': props.breakWord
		},
		props.size
	);

	let classes = classNames(
		'text-hyphenate',
		{
			clearable: props.clearable,
			locked: props.locked,
			tagged: !props.multiple && props.allowAdditions,
			'has-value': hasValue,
			'hide-no-results': loading || (props.search && searchQuery.length < props.minCharacters)
		},
		props.size,
		props.className
	);

	// Field clear button
	const clearButton = (
		<Button
			icon={{ name: 'fa-remove', solid: true }}
			solid
			circular
			size='extramini'
			hidden={!value || (props.multiple && value.length === 0) || props.disabled || props.locked}
			onClick={handleClear}
		/>
	);

	// Field option addition label
	const additionLabel = (
		<span>
			<Icon name='fa-plus' color='black' />
			{props.additionText}{' '}
		</span>
	);

	// Dropdown field
	let dropdown = (
		<div className={wrapperClasses} ref={dropdownWrapperRef}>
			{props.clearable ? <div>{clearButton}</div> : null}
			<Dropdown
				open={props.open}
				selection={props.selection}
				pointing={!props.selection}
				selectOnBlur={false}
				selectOnNavigation={false}
				openOnFocus={props.openOnFocus}
				additionLabel={additionLabel}
				className={classes}
				data-default={props.defaultValue}
				value={value !== undefined ? value : props.multiple ? [] : undefined}
				options={formatOptions(props.options)}
				allowAdditions={props.allowAdditions}
				placeholder={formatPlaceholder(props.placeholder)}
				fluid={props.fluid}
				multiple={props.multiple}
				search={props.search || props.allowAdditions ? props.search : false}
				minCharacters={!props.options ? props.minCharacters : undefined}
				loading={loading}
				scrolling={props.scrolling}
				icon={!props.locked ? { name: props.icon } : false}
				disabled={props.disabled || props.locked}
				renderLabel={(item, index, defaultLabelProps) => renderLabel(item, index, defaultLabelProps)}
				onClick={handleClick}
				onFocus={handleFocus}
				onBlur={handleBlur}
				onAddItem={handleAddItem}
				onChange={handleChange}
				noResultsMessage={props.noResultsMessage}
				onSearchChange={handleSearchChange}
			/>
			<input
				className='hidden'
				name={props.name}
				value={value === null || value === false ? '' : value}
				disabled={props.disabled}
				ref={inputRef}
				onChange={handleInputChange}
			/>
		</div>
	);

	// https://github.com/reactjs/react-docgen/issues/336
	return <>{Popup.attach(props.popup, dropdown)}</>;
});

// Documentation generation support
Component.displayName = 'Dropdown';
Component.defaultProps = DEFAULTS;

export type { Props };
export default Component;
