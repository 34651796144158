/**
 * @prettier
 * @flow
 */

import { useRef } from 'react';
import classNames from 'classnames';
import { ButtonGroup } from 'semantic-ui-react';
import { Button, Responsive, Popup } from 'liana-ui/components/';
import type { IntlComponent } from 'react-intl';
import type { Props as ButtonProps } from 'liana-ui/components/button/Button';
import type { Props as PopupProps } from 'liana-ui/components/popup/Popup';

// prettier-ignore
type Props = {
	/**
		A button group can have multiple buttons.
		PROPS[ButtonProps=/components/buttons/button/]
	*/
	buttons?: Array<ButtonProps>,
	/** A button group can have special buttons as children. */
	children?: React.Node,
	/** A button group can appear circular. */
	circular?: boolean,
	/** A button group can be pronounced by having no borders. */
	basic?: boolean,
	/** A button group can be primary color. */
	color?: 'primary',
	/** A button group can show it is off and currently unnecessary to be interacted with. */
	off?: boolean,
	/** A button groups can show it is currently disabled and unable to be interacted with. */
	disabled?: boolean,
	/** A button groups can take the whole width of its container. */
	fluid?: boolean,
	/** A button group can have no empty space around it. */
	fitted?: boolean,
	/**  A button can be aligned to the left or right of its container. */
	floated?: 'left' | 'right',
	/** A button group can be more compact. */
	compact?: boolean | 'very',
	/** A button group can have button widths divided evenly by amount of buttons. VALUES[1 - 12] */
	widths?: number,
	/** A button group can have different sizes. */
	size?: 'small' | 'tiny',
	/** Smallest device that component will be displayed with. */
	minDevice?: 'mobile' | 'tablet' | 'computer' | 'largescreen' | 'widescreen',
	/** Largest device that component will be displayed with. */
	maxDevice?: 'mobile' | 'tablet' | 'computer' | 'largescreen' | 'widescreen',
	/** Hide content on touch devices */
	hideTouch?: boolean,
	/**
		Popup text or, react-intl coomponent or object of properties for Popup component.
		PROPS[IntlComponent=/language/localisation/, PopupProps=/components/modals/popup/]
	*/
	popup?: string | IntlComponent | PopupProps
};

const DEFAULTS = {
	circular: true,
	fluid: false,
	off: false,
	disabled: false,
	compact: false,
	hideTouch: false
};

/** COMPONENT BASED ON: https://react.semantic-ui.com/elements/button/#groups */
const Component: React.AbstractComponent<Props, mixed> = React.memo<Props>((props: Props) => {
	const buttonWrapperRef = useRef(null);

	// Function to generate LianaUI ButtonGroup
	const createButtonGroup = (props: Props) => {
		// Assign classes
		const classes = classNames({
			off: props.off,
			disabled: props.disabled,
			circular: props.circular,
			fitted: props.fitted,
			two: props.fluid && props.button && props.buttons.length === 2,
			tree: props.fluid && props.button && props.buttons.length === 3,
			four: props.fluid && props.button && props.buttons.length === 4,
			five: props.fluid && props.button && props.buttons.length === 5
		});

		// Define ButtonGroup
		let buttonGroup = (
			<ButtonGroup
				className={classes}
				primary={props.color === 'primary'}
				compact={props.compact}
				basic={props.basic}
				fluid={props.fluid}
				floated={props.floated}
				size={props.size}
				widths={props.widths}
			>
				{props.children
					? props.children
					: props.buttons
					? props.buttons.map((button, index) => (
							<Button
								key={index}
								color={props.color}
								off={props.off}
								disabled={props.disabled}
								circular={false}
								{...button}
							/>
					  ))
					: null}
			</ButtonGroup>
		);

		// Display popup
		if (props.popup) {
			buttonGroup =
				typeof props.popup === 'string' || React.isValidElement(props.popup) ? (
					<Popup trigger={buttonGroup} text={props.popup} />
				) : (
					<Popup trigger={buttonGroup} {...props.popup} />
				);
		}

		return buttonGroup;
	};

	// Display reponsively
	let component =
		props.minDevice || props.maxDevice || props.hideTouch ? (
			<Responsive {...props}>{createButtonGroup(props)}</Responsive>
		) : (
			createButtonGroup(props)
		);

	return component;
});

// Documentation generation support
Component.displayName = 'Button.Group';
Component.defaultProps = DEFAULTS;

export type { Props };
export default Component;
