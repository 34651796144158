/**
 * @prettier
 * @flow
 */

import 'fomantic-ui/definitions/modules/sticky';
import ResizeSensor from 'css-element-queries/src/ResizeSensor';

// prettier-ignore
type Props = {
	/** A sticky element must have content as children.  */
	children: React.Node,
	/** A sticky element must have a context container where it should stick to. VALUES['#element'] */
	context: string,
	/** A sticky element can be active only on certain screen size. */
	mql?: string,
	/** Extra settings for Fomantic UI sticky. DATA[https://fomantic-ui.com/modules/sticky.html#/settings] */
	settings?: Settings
};

/** COMPONENT BASED ON: https://fomantic-ui.com/modules/sticky.html */
export default class Sticky extends React.PureComponent<Props> {
	mql: MediaQueryList;
	_stickyWrapper: { current: React.ElementRef<'div'> | null };
	_sticky: { current: React.ElementRef<'div'> | null };
	settings: mixed;
	resizeSensor: ResizeSensor;

	constructor(props: Props) {
		super(props);
		this._stickyWrapper = React.createRef();
		this._sticky = React.createRef();
		this.resizeSensor = null;
		this.timer = null;
	}

	static defaultProps = {
		mql: '(min-width: 1025px) and (min-height: 600px)'
	};

	componentDidMount() {
		if (typeof matchMedia === 'function' && typeof this.props.mql === 'string') {
			// Create matchmedia checking
			this.mql = matchMedia(this.props.mql);
			// Create matchmedia listener so browser check matches on page resize
			this.mql.addListener(this._mediaQueryResponse);
		}

		// Call mediaQueryResponse on initial load (this triggers plugin)
		if (this.mql && this.mql.matches) {
			this._mediaQueryResponse(this.mql);
		}
	}

	componentDidUpdate() {
		// Call mediaQueryResponse on initial load (this triggers plugin)
		if (this.mql && this.mql.matches) {
			this._mediaQueryResponse(this.mql);
		}
	}

	componentWillUnmount() {
		if (this.mql) {
			this.mql.removeListener(this._mediaQueryResponse);
		}

		if (this.resizeSensor) {
			this.resizeSensor.detach($(this.props.context));
		}

		this._destroySticky();
	}

	_getSettings = () => {
		return Object.assign(
			{
				observeChanges: true,
				visibility: { settings: { silent: true } },
				context: this.props.context && $(this.props.context).length === 1 ? this.props.context : false
			},
			this.props.settings
		);
	};

	_mediaQueryResponse = (mql: MediaQueryList | MediaQueryListEvent) => {
		if (mql && mql.matches) {
			this._setSticky();
		} else {
			this._destroySticky();
		}
	};

	_setSticky = () => {
		$(this._sticky.current).sticky(this._getSettings());

		// Fix FUI Sticky positioning: https://github.com/fomantic/Fomantic-UI/issues/466
		if (this.props.context) {
			this.resizeSensor = new ResizeSensor($(this.props.context), () => {
				clearTimeout(this.timer);
				this.timer = setTimeout(() => {
					$(this._sticky.current).sticky('refresh');
				}, 20);
			});
		}
	};

	_destroySticky = () => {
		clearTimeout(this.timer);
		$(this._sticky.current).sticky('destroy');
	};

	/** Refresh sticky */
	refresh = () => {
		return $(this._sticky.current).sticky('refresh');
	};

	/** Get reference */
	get $ref() {
		return this._sticky;
	}

	render() {
		return (
			<div className='sticky-wrapper' ref={this._stickyWrapper}>
				<div className='ui sticky' ref={this._sticky}>
					{this.props.children}
				</div>
			</div>
		);
	}
}
