/**
 * @prettier
 * @flow
 */

// DO NOT USE JQUERY IN THIS FILE! NODE CHART EXPORT WILL FAIL!

import { isEmpty, merge } from 'lodash';
import Time from 'liana-ui/definitions/time/Time';
import ColorUtils from './utils/ColorUtils';
import DateUtils from './utils/DateUtils';

const EXPORT_SERVER = 'https://export.highcharts.com/';
const ANIMATION = 700; // Animation duration

type Props = {
	intl: Intl,
	/** Allowed types of charts (line, area, sparkline, column, bar, pie) */
	type: 'line' | 'area' | 'sparkline' | 'column' | 'bar' | 'pie',
	/** Data provided by backen. The JSON must be formatted correctly! */
	data: Data,
	/** Highcharts settings object that can overrule all props above */
	settings: Settings,
	/**
		A chart can have different predefined themes or a custom theme.
		VALUES['liana' | 'rating' | {colors: ['#FF0000', '#FFF000', ...]}]
	*/
	theme?: string | { colors: Array<string> },
	/** Inverted color chart on colored background */
	isInverted: boolean,
	/** Boxed chart with labels inside the box */
	isBoxed: boolean,
	/** If chart should animate on render */
	isAnimated: boolean,
	/** If chart should allows exporting through context menu */
	export?: $Shape<Export>,
	/** Custom height of chart */
	height: number,
	/** Custom width of chart */
	width: number,
	/** Text to display if the chart can not be drawn with current data */
	noDataText: string,
	/** Data point (marker) click callback */
	tooltip: () => mixed,
	/** Data point (marker) click callback */
	onMarkerClick?: (mixed) => mixed,
	/** Data point (marker) hover callback */
	onMarkerHover?: (mixed) => mixed,
	/** Timezone for chart. Default is this.context.user.get('timezone') */
	timezone: string,
	/** Timezone offset in seconds for chart. Default is this.context.user.get('timezone_offset') */
	timezoneOffset: string | number
};

type Export = {
	url: string,
	type: string,
	filename: string
};

type Data = {
	series: Array<{
		name: string,
		data: Array<*>
	}>,
	xAxis: {
		plotLines: Array<*>,
		startOnTick: boolean,
		tickmarkPlacement: string,
		categories: Array<string>
	},
	yAxis: Array<*>
};

type Plotline = {
	value: number,
	label: {
		x: number,
		y: number
	}
};

type Colors = {
	colors: Array<string>
};

type Settings = $Shape<{
	xAxis: any,
	yAxis: any,
	error: boolean
}>;

const defaultProps = {
	type: 'line',
	theme: 'liana',
	data: {},
	settings: {},
	height: 285,
	isAnimated: false,
	isBoxed: false
};

/** This file is required for Chart Export -functionality */
export default class ChartSettings {
	props: Props;
	settings: Settings;
	error: boolean;
	hasIncompleteRanges: boolean;
	timeBased: boolean;
	displayRange: 'hours' | 'days' | 'weeks' | 'months';
	colors: Colors;
	globalSettings: Settings;
	typeSettings: ?{};
	exportDefaults: { enabled: boolean, type: string, url: string };
	dateTimeOpts: {
		timezone: string, // User timezone
		offset: string | number, // User timezone offset
		current: string // User current time
	};

	constructor(props: Props = {}) {
		if (!isEmpty(props)) {
			this.initialize(props);
		}
	}

	initialize(props: Props) {
		this.props = Object.assign({}, defaultProps, props);
		this.hasIncompleteRanges = false;
		this.timeBased = false;
		this.displayRange = 'days';
		this.error = false;

		this.dateTimeOpts = {
			timezone: props.timezone || 'Europe/Helsinki',
			offset: props.timezoneOffset || 7200,
			current: Time.get(props.timezoneOffset || 7200, 'now', 'isoUTC')
		};

		this.exportDefaults = {
			enabled: true,
			url: EXPORT_SERVER,
			type: 'image/png'
		};

		this.globalSettings = require('./settings/global.json');
		// $FlowFixMe String literals aren't allowed as asset paths
		this.typeSettings = require('./settings/' + props.type + '.json');

		const colorUtils = new ColorUtils(this.props.theme);
		this.colors = colorUtils.colors;
	}

	generate(props: Props = {}) {
		if (!isEmpty(props)) {
			this.initialize(props);
		}

		this.settings = merge(
			{},
			this.globalSettings,
			this.typeSettings,
			this.colors,
			this._formatData(this.props.data),
			this._setTexts(),
			this._setDimensions(),
			this._setBoxed(),
			this._setTooltip(this.props.data),
			{
				plotOptions: {
					series: {
						animation: this.props.isAnimated,
						duration: ANIMATION
					}
				}
			},
			this.props.settings
		);

		this.settings.error = this.error;
		return this.settings;
	}

	_setTexts = () => {
		let emptyText = '';
		let emptyColor = {};
		if (this.props.noDataText) {
			emptyText = this.props.noDataText;
		} else if (this.error) {
			emptyColor = { noData: { style: { color: 'red' } } };
			emptyText = this._translateText('component.chart.lang.invalidData');
		} else {
			emptyText = this._translateText('component.chart.lang.noData');
		}

		return Object.assign(
			{},
			{
				lang: {
					contextButtonTitle: this._translateText('component.chart.lang.contextButtonTitle'),
					downloadJPEG: this._translateText('component.chart.lang.downloadJPEG'),
					downloadPDF: this._translateText('component.chart.lang.downloadPDF'),
					downloadPNG: this._translateText('component.chart.lang.downloadPNG'),
					downloadSVG: this._translateText('component.chart.lang.downloadSVG'),
					loading: this._translateText('component.chart.lang.loading'),
					noData: emptyText
				}
			},
			emptyColor
		);
	};

	_translateText = (key: string, amount?: number) => {
		if (key && isNaN(amount)) {
			return key.indexOf('.') !== -1 ? this.props.intl.formatMessage({ id: key }) : key;
		} else if (key && !isNaN(amount)) {
			return key.indexOf('.') !== -1 ? this.props.intl.formatMessage({ id: key }, { amount }) : key;
		}
		return key;
	};

	_setDimensions = () => {
		let dimensions = {};
		if (this.props.height && !this.props.width) {
			dimensions = { chart: { height: this.props.height } };
		} else if (this.props.width) {
			dimensions = { chart: { width: this.props.width, height: this.props.height } };
		}
		return dimensions;
	};

	_setBoxed = () =>
		ChartSettings.isBoxed(this.props, this.error)
			? {
					chart: {
						borderRadius: 15,
						plotBorderWidth: 0,
						marginTop: 22,
						marginRight: 22,
						spacingTop: 15,
						spacingRight: 15,
						spacingBottom: 15,
						spacingLeft: 15
					}
			  }
			: {};

	_setTooltip = (data: Data) => {
		const _component = this;
		if (!_component.props.tooltip) {
			return {
				tooltip: {
					formatter() {
						let label = '';
						const unit = data.series[this.series.index].unit // $FlowFixMe Nested uncertainty
							? ' ' + _component._translateText(data.series[this.series.index].unit, this.y)
							: '';
						const seriesType =
							data.series.length > 1 ? data.series[this.series.index].type : _component.props.type;

						if (_component.props.type === 'pie' && data.series.length > 1) {
							_component.timeBased = false;
						}

						if (_component.timeBased) {
							/* line, area and sparkline have a extra marker added to draw the line all the way to the left edge of the chart */
							if (
								seriesType === 'line' ||
								seriesType === 'area' ||
								seriesType === 'sparkline' ||
								seriesType === 'spline'
							) {
								label = _component._getTooltipLabel(data.xAxis.categories[this.point.index - 1]);
							} else {
								label = _component._getTooltipLabel(data.xAxis.categories[this.point.index]);
							}
						} else {
							label = _component._translateText(data.xAxis.categories[this.point.index]);
						}

						if (this.x === -0.5) {
							return false;
						} else if (_component.props.type === 'pie') {
							return `<div class="chart-tooltip">
									<div><span class="color ${this.color.toUpperCase() === '#FFFFFF' ? 'color-shadow' : ''}" style="color:${
								this.color
							}">\u25A0</span> <span class="text-bolder">${
								data.series.length > 1
									? _component._translateText(data.series[this.point.index].name)
									: label
							}</span></div>
									<div class="text-bold text-bigger-10">${Math.round(this.percentage * 10) / 10}%</div>
									<div>(${_component.props.intl.formatNumber(this.y)}${unit})</div>
								</div>`;
						} else {
							return `<div class="chart-tooltip">
									<div><span class="color ${this.color.toUpperCase() === '#FFFFFF' ? 'color-shadow' : ''}" style="color:${
								this.color
							}">\u25A0</span> <span class="text-bolder">${this.series.name}</span></div>
									<div>${label}</div>
									<div class="text-bold text-bigger-10">${_component.props.intl.formatNumber(this.y)}${unit}</div>
								</div>`;
						}
					}
				}
			};
		} else {
			return { tooltip: { formatter: _component.props.tooltip } };
		}
	};

	_getTooltipLabel = (dateStr: string) => {
		let date = new Date(DateUtils.formatISO(dateStr)),
			start = '',
			stop = '';
		switch (this.displayRange) {
			case 'hours':
				start = this.props.intl.formatDate(date, {
					timeZone: this.dateTimeOpts.timezone,
					hour: 'numeric',
					minute: 'numeric'
				});
				date.setHours(date.getHours() + 1);
				stop = this.props.intl.formatDate(date, {
					timeZone: this.dateTimeOpts.timezone,
					hour: 'numeric',
					minute: 'numeric'
				});
				break;
			case 'days':
				start = this.props.intl.formatDate(date, {
					timeZone: this.dateTimeOpts.timezone,
					weekday: 'short',
					month: 'numeric',
					day: 'numeric'
				});
				break;
			case 'weeks':
				start =
					this._translateText('date.range.week') +
					': ' +
					this.props.intl.formatDate(date, {
						timeZone: this.dateTimeOpts.timezone,
						weekday: 'short',
						month: 'numeric',
						day: 'numeric'
					});
				date.setDate(date.getDate() + 7);
				stop = this.props.intl.formatDate(date, {
					timeZone: this.dateTimeOpts.timezone,
					weekday: 'short',
					month: 'numeric',
					day: 'numeric'
				});
				break;
			case 'months':
				start = this.props.intl.formatDate(date, {
					timeZone: this.dateTimeOpts.timezone,
					month: 'long',
					year: 'numeric'
				});
				break;
		}
		return start !== '' && stop !== '' ? start + ' - ' + stop : start;
	};

	_formatData = (propData: Data) => {
		// data must must be in correct format
		if (!this._isValidData(propData)) {
			return {};
		}
		const formatCompactShortNumber = (val) => this.props.intl.formatNumber(val, this.props.compactNumbers);
		let data = ((JSON.parse(JSON.stringify(propData)): any): Data), // Deep clone data without re-referencing
			type = this.props.type;

		// Determine if chart X axis should be time based
		this.timeBased = DateUtils.isValidDate(data.xAxis.categories[0]);

		// If X axis is time based
		if (this.timeBased) {
			// Determine what range (hours, days, weeks, months) to display on X axis
			const lSec = new Date(data.xAxis.categories[0]).getTime(),
				rSec = new Date(data.xAxis.categories[1]).getTime(),
				dr: 'hours' | 'days' | 'weeks' | 'months' = (DateUtils.getDisplayRange(rSec - lSec): any);
			if (dr) {
				this.displayRange = dr;
			}
		}

		let zeroChart = true;
		for (let i = 0; i < data.series.length; i++) {
			// Translate series names
			data.series[i].name = this._translateText(data.series[i].name);

			for (let j = 0; j < data.series[i].data.length; j++) {
				// Check if all data is zero
				if (data.series[i].data[j].y > 0) {
					zeroChart = false;
				}

				// Store original time and range to marker for sending request to API
				if (this.timeBased) {
					data = this._setTimeAndRange(data, i, j);
				}
			}
		}

		// Draw zero lines to the bottom of the chart
		if (zeroChart) {
			data = merge({}, data, {
				plotOptions: {
					line: {
						softThreshold: false
					},
					spline: {
						softThreshold: false
					}
				}
			});
		}

		if (this.props.compactNumbers) {
			data = merge({}, data, {
				plotOptions: {
					series: {
						dataLabels: {
							formatter() {
								return formatCompactShortNumber(this.y);
							}
						}
					}
				},
				yAxis: {
					labels: {
						formatter() {
							return formatCompactShortNumber(this.value);
						}
					}
				}
			});
		}

		// Match multiple yAxis colors to series colors
		if ('yAxis' in data) {
			for (let i = 0; i < data.yAxis.length; i++) {
				data.yAxis[i] = merge({}, this.globalSettings.yAxis, data.yAxis[i], {
					labels: { style: { color: this.colors.colors[i] } }
				});
			}
		}

		// Place plot lines according to date
		if (this.timeBased && 'plotLines' in data.xAxis) {
			for (let i = 0; i < data.xAxis.plotLines.length; ++i) {
				data.xAxis.plotLines[i] = merge(
					{},
					this.globalSettings.xAxis.plotLines[0],
					this._formatPlotLine(data.xAxis.plotLines[i], data.xAxis.categories, i)
				);
			}
		}

		for (let i = 0; i < data.xAxis.categories.length; ++i) {
			if (this.timeBased) {
				data.xAxis.categories[i] = DateUtils.formatISO(data.xAxis.categories[i]);

				const dateTime = data.xAxis.categories[i],
					day = Number(DateUtils.parseDateData(dateTime, 'day')),
					hour = Number(DateUtils.parseDateData(dateTime, 'hour'));

				// Draw dotted line/bar between ranges that are currently being processed and are still incomplete (time is currently now or in the future)
				if (this.timeBased && this.dateTimeOpts.current) {
					if (DateUtils.checkIncomplete(this.dateTimeOpts.current, dateTime, this.displayRange) === true) {
						data = this._setIncompleteRange(data, i);
					}
				}

				// Localize dates and times
				switch (this.displayRange) {
					case 'hours':
						if (hour === 0) {
							data.xAxis.categories[i] = this.props.intl.formatDate(data.xAxis.categories[i], {
								timeZone: this.dateTimeOpts.timezone,
								weekday: 'short',
								month: 'short',
								day: 'numeric'
							});
						} else {
							data.xAxis.categories[i] = this.props.intl.formatDate(data.xAxis.categories[i], {
								timeZone: this.dateTimeOpts.timezone,
								hour: 'numeric',
								minute: 'numeric'
							});
						}
						break;
					case 'days':
						if (day === 1) {
							data.xAxis.categories[i] = this.props.intl.formatDate(data.xAxis.categories[i], {
								timeZone: this.dateTimeOpts.timezone,
								weekday: 'short',
								year: 'numeric',
								month: 'short',
								day: 'numeric'
							});
						} else {
							data.xAxis.categories[i] = this.props.intl.formatDate(data.xAxis.categories[i], {
								timeZone: this.dateTimeOpts.timezone,
								weekday: 'short',
								month: 'short',
								day: 'numeric'
							});
						}
						break;
					case 'weeks':
						data.xAxis.categories[i] =
							this._translateText('date.range.week') +
							': ' +
							this.props.intl.formatDate(data.xAxis.categories[i], {
								timeZone: this.dateTimeOpts.timezone,
								weekday: 'short',
								month: 'short',
								day: 'numeric'
							});

						break;
					case 'months':
						data.xAxis.categories[i] = this.props.intl.formatDate(data.xAxis.categories[i], {
							timeZone: this.dateTimeOpts.timezone,
							month: 'short',
							year: 'numeric'
						});
						break;
				}
			} else {
				// Translate category names
				data.xAxis.categories[i] = this._translateText(data.xAxis.categories[i]);
			}
		}

		// If non of the data ranges are incomplete draw line to the right edge of the chart
		if (this.timeBased && !this.hasIncompleteRanges && type !== 'pie') {
			data = merge({}, data, {
				xAxis: {
					max: data.xAxis.categories.length - 1
				}
			});
			for (let j = 0; j < data.series.length; ++j) {
				data.series[j].data.push({
					x: data.xAxis.categories.length + 0.5,
					y: data.series[j].data[data.series[j].data.length - 1].y,
					marker: {
						states: {
							hover: {
								enabled: false
							}
						}
					}
				});
			}
		}

		// Draw line to left edge of chart
		if (this.timeBased && (type === 'line' || type === 'area' || type === 'sparkline')) {
			data = merge({}, data, {
				xAxis: {
					min: 0
				}
			});

			for (let j = 0; j < data.series.length; ++j) {
				if (
					!data.series[j].type ||
					data.series[j].type === 'line' ||
					data.series[j].type === 'spline' ||
					data.series[j].type === 'area'
				) {
					data.series[j].data.unshift({
						x: -0.6,
						y: data.series[j].data[0].y,
						marker: {
							states: {
								hover: {
									enabled: false
								}
							}
						}
					});
				}
			}
		}

		// If X axis contains text categories
		if (!this.timeBased) {
			data.xAxis.startOnTick = true;
			data.xAxis.tickmarkPlacement = 'between';
		}

		// Show title on chart image export
		if (this.props.export && this.props.export.title) {
			data = merge({}, data, {
				exporting: {
					chartOptions: {
						chart: {
							backgroundColor: this.globalSettings.chart.backgroundColor,
							marginTop: 40,
							marginRight: 10,
							spacingTop: 10,
							spacingRight: 10,
							spacingBottom: 10,
							spacingLeft: 10
						},
						title: {
							text: this.props.export.title
						}
					}
				}
			});
		}

		// If data has multiple series display whole series as one piece of the pie
		if (type === 'pie') {
			if (data.series.length > 1) {
				const piecatiegories = [],
					time = data.series[0].data[0].time,
					range = data.series[0].data[0].range,
					range_amount = data.series[0].data.length;

				for (let i = 0; i < data.series.length; ++i) {
					let sum = 0;
					let data2 = {};
					piecatiegories.push(data.series[i].name);
					for (let j = 0; j < data.series[i].data.length; ++j) {
						sum += data.series[i].data[j].y;
					}

					data2 = merge({}, data.series[i].data[0], {
						y: sum,
						time,
						range,
						range_amount
					});
					data.series[i].data = [];
					data.series[0].data.push(data2);
				}
				data.xAxis.categories = piecatiegories;
			}

			// Format pie categories as labels
			const intl = this.props.intl;
			data = merge({}, data, {
				plotOptions: {
					series: {
						dataLabels: {
							formatter() {
								const cat = this.series.chart.xAxis[0].categories,
									x = this.point.x;
								return cat[x];
							}
						}
					}
				},
				legend: {
					labelFormatter: function () {
						const cat = this.series.chart.xAxis[0].categories,
							x = this.x;
						return `${cat[x]}: ${
							Math.round(this.percentage * 10) / 10
						}% <span class="ui text grey text-smaller-10">(${intl.formatNumber(this.y)})</span>`;
					}
				}
			});
		}

		// Marker click support
		if (this.props.onMarkerClick) {
			const callback = this.props.onMarkerClick;
			data = merge({}, data, {
				plotOptions: {
					series: {
						cursor: 'pointer',
						point: {
							events: {
								click() {
									if (this.y > 0) {
										callback(this);
									}
								}
							}
						}
					}
				}
			});
		}

		// Marker hover support
		if (this.props.onMarkerHover) {
			const callback = this.props.onMarkerHover;
			data = merge({}, data, {
				plotOptions: {
					series: {
						cursor: 'pointer',
						point: {
							events: {
								mouseOver() {
									callback(this);
								}
							}
						}
					}
				}
			});
		}

		return data;
	};

	_formatPlotLine = (plotline: Plotline, categories: Array<string>, i: number) => {
		let index = null,
			categoryseconds = null,
			nextseconds = null,
			plotseconds = new Date(DateUtils.formatISO(plotline.value)).getTime(),
			plotlinedate = this.props.intl.formatDate(DateUtils.formatISO(plotline.value), {
				timeZone: this.timezone,
				weekday: 'short',
				month: 'short',
				day: 'numeric',
				hour: 'numeric',
				minute: 'numeric'
			});

		for (let j = 0; j < categories.length; ++j) {
			categoryseconds = new Date(DateUtils.formatISO(categories[j])).getTime();
			nextseconds = categories[i + 1]
				? new Date(DateUtils.formatISO(categories[j + 1])).getTime()
				: new Date(DateUtils.getNextDate(DateUtils.formatISO(categories[j]), this.displayRange)).getTime();

			if (!index && plotseconds >= categoryseconds && plotseconds <= nextseconds) {
				const offset =
					((plotseconds - categoryseconds) * 100) / DateUtils.getRangeSeconds(this.displayRange) / 100;
				index = j + offset;
				const text = plotline.label.text;
				plotline.label.text = '';
				plotline.value = index;
				plotline.events = {
					mouseenter() {
						let label = `
							<div class='plotline-label'>
								<div class='plotline-label-header'>
									<div class='plotline-label-line' style='background-color: ${plotline.color};'></div>
									${plotlinedate}
								</div>
								${text}
							<div>`;
						this.label.element.innerHTML = label;
						this.axis.chart.tooltip.hide();
						this.axis.chart.tooltip.options.enabled = false;
					},
					mousemove() {
						this.axis.chart.tooltip.hide();
						this.axis.chart.tooltip.options.enabled = false;
					},
					mouseout() {
						this.axis.chart.tooltip.options.enabled = true;
						this.label.element.innerHTML = '';
					}
				};

				// Show label on left side if too close to right edge of Chart
				let leftOffset = (index / categories.length) * 100;
				if (this.props.type !== 'bar' && leftOffset > 70) {
					plotline.label.x = offset - 310;
				}

				if (this.props.type === 'bar' && leftOffset > 70) {
					plotline.label.y = -50;
				}
			}
		}

		return plotline;
	};

	_setTimeAndRange = (data: Data, i: number, j: number) => {
		data.series[i].data[j] = merge(data.series[i].data[j], {
			time: data.xAxis.categories[j],
			range: this.displayRange,
			range_amount: 1
		});
		return data;
	};

	_isValidData = (propData: Data) => {
		// If valid data
		if (
			propData.series &&
			Array.isArray(propData.series) &&
			propData.series.length > 0 &&
			propData.series[0].hasOwnProperty('data') &&
			Array.isArray(propData.series[0].data) &&
			propData.series[0].data.length > 0 &&
			typeof propData.series[0].data[0] === 'object' &&
			propData.series[0].data[0].hasOwnProperty('y') &&
			propData.xAxis &&
			typeof propData.xAxis === 'object' &&
			propData.xAxis.hasOwnProperty('categories') &&
			Array.isArray(propData.xAxis.categories) &&
			propData.xAxis.categories.length > 0 &&
			propData.series[0].data.length === propData.xAxis.categories.length
		) {
			return true;
		}

		// If not empty data
		if (!isEmpty(propData)) {
			this.error = true;
		}
		return false;
	};

	_setIncompleteRange = (data: Data, i: number) => {
		const type = this.props.type;
		// If last data point is incomplete (this hour, today etc.) draw dotted line
		if (type === 'line' || type === 'area' || type === 'sparkline' || type === 'bar') {
			data = merge({}, data, {
				plotOptions: {
					series: {
						zoneAxis: 'x',
						zones: [{ value: i - 1 }, { dashStyle: 'Dot' }]
					}
				}
			});
		}

		// If last data point is incomplete (this hour, today etc.) draw lighter bar
		if (type === 'column' || type === 'bar') {
			// TODO: Need to implement new 5.0 style of dotted borders: http://jsfiddle.net/d_paul/7xz67eyo/
			// Highcharts.seriesTypes.column.prototype.pointAttrToOptions.dashstyle = 'dashStyle';
			for (let j = 0; j < data.series.length; j++) {
				for (let k = i; k < data.series[j].data.length; k++) {
					data.series[j].data[k] = merge({}, data.series[j].data[k], {
						color: ColorUtils.hexToRGB(this.colors.colors[j], 0.3),
						dashStyle: 'Dot'
					});
				}
			}
		}
		this.hasIncompleteRanges = true;
		return data;
	};

	// Public function - Verify "boxed" status
	static isBoxed = (props: Props, error: boolean = false) => isEmpty(props.data) || error || props.isBoxed;
}
