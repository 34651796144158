/**
 * @prettier
 * @flow
 */

import { useIntl } from 'react-intl';
import PageHeaderContext from './src/PageHeaderContext';
import { Breadcrumb, ActionHeader } from 'liana-ui/components/';
import type { Props as BreadcrumbProps } from 'liana-ui/components/breadcrumb/Breadcrumb';
import type { Props as ActionHeaderProps } from 'liana-ui/components/header/ActionHeader';

// prettier-ignore
type Props = {
    /** A page header must have an application name to display in browser tab. */
    appName: string,
    /**
        Page header can have a breadcrumb.
        PROPS[BreadcrumbProps=/components/menus/breadcrumb/]
    */
    breadcrumb?: BreadcrumbProps,
    /**
        Page header can have a simple header without actions.
        PROPS[HeaderProps=/components/texts/header/]
    */
    header?: HeaderProps,
    /**
        Page header can have an more complex action header with actions, filters etc.
        PROPS[ActionHeaderProps=/components/containers/action-header/]
    */
    actionHeader?: ActionHeaderProps
};

const DEFAULTS = {
	appName: 'Liana'
};

/* Note: Do NOT use React.Memo for this component */
const Component = (props: Props) => {
	const intl = useIntl();
	let key = null,
		title = [];

	if (Array.isArray(props.breadcrumb?.sections) && props.breadcrumb.sections.length > 1) {
		for (let i = 0; i < props.breadcrumb.sections.length; i++) {
			key = props.breadcrumb.sections[i].page; // Convenience
			title.push(
				React.isValidElement(key) && key.props?.id
					? intl.formatMessage({ id: key.props.id }, key.props.values) // Translate a <FormatMessage> component
					: key // Basic text
			);
		}

		title.reverse();
	} else if (props.actionHeader?.header?.text || props.header?.text) {
		key = props.actionHeader?.header?.text || props.header?.text; // Convenience
		title.push(
			React.isValidElement(key) && key.props?.id
				? intl.formatMessage({ id: key.props.id }, key.props.values) // Translate a <FormatMessage> component
				: key // Basic text
		);
	}

	if (props.appName) {
		title.push(props.appName);
	}
	document.title = title.join(' - ');

	const getBreadcrumb = () => {
		if (props.breadcrumb?.sections && props.breadcrumb.sections.length > 1) {
			return <Breadcrumb sections={props.breadcrumb.sections} />;
		}
		return null;
	};

	const getActionHeader = () => {
		if (props.header) {
			return <ActionHeader page={true} header={props.header} />;
		} else if (props.actionHeader) {
			let contextMenuProps = props.actionHeader?.contextMenu;
			if (contextMenuProps) {
				contextMenuProps = {
					...contextMenuProps,
					text: intl.formatMessage({ id: 'component.context-menu.actions' })
				};
			}
			return <ActionHeader page={true} {...props.actionHeader} contextMenu={contextMenuProps} />;
		}
		return null;
	};

	return (
		<div className='page-header'>
			{getBreadcrumb()}
			{getActionHeader()}
		</div>
	);
};

// Documentation generation support
Component.displayName = 'PageHeader';
Component.defaultProps = DEFAULTS;
Component.Context = PageHeaderContext;

export type { Props };
export default Component;
