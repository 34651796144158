/**
 * @prettier
 * @flow
 */

import { FormattedMessage } from 'react-intl';
import { Dropdown } from 'semantic-ui-react';
import { Icon, Segment, Navigation, Popup } from 'liana-ui/components/';
import type { Props as PopupType } from 'liana-ui/components/popup/Popup';

// prettier-ignore
type Props = {
	/** If is open */
	open: boolean,
	/** Mobile navigation tools (only visible on mobile and tablet screen sizes). Should be the same as above the main navigation in SidePanel on desktop. */
	tools?: React.Node,
	/** User menu data. User object: DATA[json/user/user.json] */
	user: {
		id: string,
		organization: string,
		name: string,
		email: string,
		organizations: Array<{ name: string, ... }>,
		...
	},
	/** Mobile navigation data (only visible on mobile and tablet screen sizes). Array of pages object: DATA[json/navigation/navigation.json] */
	navigation: Array<{ text: string, icon: string, link: string, ... }>,
	/** Position, with and heigh of dropdown menu */
	style: { [key: string]: string },
	/** Object of properties for Popup */
	popup?: PopupType,
	/** Use plaintext translations */
	raw: boolean,
	/** Called on when clicking on a link. */
	onLinkClick?: (
		event: SyntheticEvent<>,
		data: any
	) => void,
	/** Called on menu open. */
	onOpen?: (
		event: SyntheticEvent<>,
		data: { id: string }
	) => void,
	/** Called on menu close. */
	onClose?: (
		event: SyntheticEvent<>,
		data: { id: string }
	) => void
};

const DEFAULTS = {
	open: false,
	raw: false
};

const ID = 'navigation';

/** COMPONENT BASED ON: https://react.semantic-ui.com/modules/dropdown/ and https://react.semantic-ui.com/collections/menu/ */
const Component: React.AbstractComponent<Props, mixed> = React.memo<Props>((props: Props) => {
	// Render nothing if empty
	if (!props.navigation) {
		return null;
	}

	const isMobile =
		document.querySelector('html').classList.contains('mobile') ||
		document.querySelector('html').classList.contains('tablet');

	const handleOpen = (event: ?SyntheticEvent<>, data: any) => {
		if (typeof props.onOpen === 'function') {
			props.onOpen(event, handleCallbackData(data));
		}
	};

	const handleClose = (event: ?SyntheticEvent<>, data: any) => {
		if (typeof props.onClose === 'function') {
			props.onClose(event, handleCallbackData(data));
		}
	};

	// Handle data returned by onClose callback.
	const handleCallbackData = (data: any) => ({
		id: data.id
	});

	let menu = (
		<Dropdown
			id={`${ID}Menu`}
			tabIndex={false}
			closeOnBlur={false}
			open={props.open}
			item
			icon={
				<Icon
					name={isMobile && props.open ? 'close' : 'sidebar'}
					size={isMobile && props.open ? undefined : 'large'}
					circular={isMobile && props.open ? true : false}
				/>
			}
			onOpen={handleOpen}
			onClose={handleClose}
			multiple
		>
			<Dropdown.Menu
				id={`${ID}Dropdown`}
				className={`${ID}Dropdown`}
				style={props.style}
				onClick={(event) => event.stopPropagation()}
				size='large'
			>
				{props.tools ? (
					<Segment basic compressed removeMargins='all'>
						{props.tools}
					</Segment>
				) : null}
				<Navigation
					maxLevels={3}
					navigation={props.navigation}
					disableParentLinks
					raw={props.raw}
					onLinkClick={(event, data) => {
						if (typeof props.onLinkClick === 'function') {
							props.onLinkClick(event, data);
						}
						props.onClose();
					}}
				/>
			</Dropdown.Menu>
		</Dropdown>
	);

	// Display popup
	menu = !props.open ? (
		<Popup trigger={menu} text={<FormattedMessage id='component.navigation-menu.name' />} {...props.popup} />
	) : (
		menu
	);

	return menu;
});

// Documentation generation support
Component.displayName = 'NavigationMenu';
Component.defaultProps = DEFAULTS;

export type { Props };
export default Component;
