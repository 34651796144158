/**
 * @prettier
 * @flow
 */

import { FormattedMessage } from 'react-intl';
import { Sidebar } from 'semantic-ui-react';
import { Segment, Header, Button } from 'liana-ui/components/';

// prettier-ignore
type Props = {
	/** 
	 	A modal can have a header text. 
	 	PROPS[IntlComponent=/language/localisation/, HeaderProps=/components/texts/header]
	*/
	header: string | IntlComponent | HeaderProps,
	/** Content as children. */
	children?: React.Node,
	/** Controls whether or not the sidebar is visible on the page. */
	visible: boolean,
	/** Controls whether or not the sidebar has padding. */
	padded: boolean,
	/** A panel or modal can have different widths. */
	width?: 'thin' | 'wide' | 'very wide' | 'fullscreen',
	/** Called before a sidebar begins to animate out. */
	onHide?: (
		event: SyntheticEvent<>
	) => void
};

const DEFAULTS = {
	visible: false,
	padded: true
};

/** COMPONENT BASED ON: https://react.semantic-ui.com/modules/sidebar/ */
const Component: React.AbstractComponent<Props, mixed> = React.memo<Props>((props: Props) => {
	let mobile = document.querySelector('html').classList.contains('mobile');

	const handleHide = (event) => {
		if (typeof props.onHide === 'function') {
			props.onHide(event);
		}
	};

	const getHeader = () => {
		if (props.header) {
			const closeButton = <Button icon='close' size='mini' fitted circular onClick={handleHide} />;
			return typeof props.header === 'string' ? (
				<Header
					text={<FormattedMessage id={props.header} />}
					size='large'
					dividing
					floatedContent={closeButton}
				/>
			) : (
				<Header {...props.header} size='large' dividing floatedContent={closeButton} />
			);
		} else {
			return null;
		}
	};

	return (
		<Sidebar
			visible={props.visible}
			width={props.width}
			animation='overlay'
			direction={mobile ? 'bottom' : 'right'}
			onHide={(event) => props.onHide(event)}
		>
			{props.padded ? (
				<Segment basic removeMargins='bottom'>
					{getHeader()}
					{props.children}
				</Segment>
			) : (
				<>
					{getHeader()}
					{props.children}
				</>
			)}
		</Sidebar>
	);
});

// Documentation generation support
Component.displayName = 'Sidebar';
Component.defaultProps = DEFAULTS;

// Attach Subcomponents
Component.Pushable = Sidebar.Pushable;
Component.Pusher = Sidebar.Pusher;

export type { Props };
export default Component;
